import { Box, Divider, Grid, IconButton, Link, Paper, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch } from "react-redux";
import { useClearCartMutation } from "../store/cart/cartApis";
import { setSnackBar } from "../store/common/commonSlice";

export const CheckoutComponent = ({ cartsItemCount, cartsData, add, remove, show }) => {
    const dispatch = useDispatch();
    const [handleClearCart] = useClearCartMutation();

    const removeCart = async () => {
        remove(false);
        handleClearCart({ cart_id: cartsData[0].cart_id }).unwrap().then(fulfilled => {
            dispatch(setSnackBar({
                open: true,
                message: fulfilled.message,
                severity: "success",
            }))
        }).catch(rejected => dispatch(setSnackBar({
            open: true,
            message: rejected.message,
            severity: "error",
        })));
    }

    return (
        <Grid item xs={6} sx={{ display: show ? "block" : "none" }}>
            <Paper sx={{ p: 3 }}>
                <Grid container >
                    <Grid item xs={10}>
                        <Typography variant="h6" fontWeight={700}>Shopping Cart</Typography>
                    </Grid>
                    <Grid item xs={2} textAlign={"right"}>
                        <Typography variant="subtitle2">Item Selected</Typography>
                        <Link color={"secondary"} sx={{ cursor: "pointer" }} fontWeight={600} onClick={() => removeCart()}>Clear All</Link>
                    </Grid>
                </Grid>
                {
                    cartsItemCount ? cartsData.map((val, key) => {
                        return (
                            <Grid container pb={2} pt={2} key={key}>
                                <Grid item xs={10}>
                                    <Grid container>
                                        <Grid item xs={3} sx={{ width: "100%", height: "100%", borderRadius: 2, alignSelf: "center" }}>
                                            <Box
                                                component="img"
                                                sx={{ width: "100%", height: "100%", borderRadius: 2 }}
                                                alt={val.name}
                                                src={val.image}
                                            />
                                        </Grid>
                                        <Grid item xs={9} p={2}>
                                            <Typography variant="h6" fontWeight={700}>{val.name}</Typography>
                                            <Typography variant="caption">${val.price} X {val.qty}</Typography>
                                            <Grid item xs={4} display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                                {
                                                    val.qty === 1
                                                        ? <IconButton onClick={() => remove(val.product_id)} sx={{ p: "2px", border: "1px solid" }} size="small" color="secondary"><DeleteIcon /></IconButton>
                                                        : <IconButton onClick={() => remove(val.product_id)} sx={{ p: "2px", border: "1px solid" }} size="small" color="secondary"><RemoveIcon /></IconButton>
                                                }
                                                <Typography variant="BUTTON" sx={{ p: 1 }}>{val.qty}</Typography>
                                                <IconButton onClick={() => add({ id: val.product_id })} sx={{ p: "2px", border: "1px solid" }} size="small" color="secondary"><AddIcon /></IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} textAlign={"center"} alignSelf={"center"}>
                                    <Typography variant="h6" fontWeight={700} color={"secondary"}>{val.price * val.qty}</Typography>
                                </Grid>
                            </Grid>
                        )
                    })
                        : null
                }

            </Paper>
        </Grid>
    )
}