import { createSlice } from "@reduxjs/toolkit";

const initialState = {
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
    }
});

// Action creators are generated for each case reducer function
export const { } = userSlice.actions;

export default userSlice;
