import { Grid, IconButton, Paper, Typography } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import { format } from "date-fns";
import { parseISO } from "date-fns";

export const DeliveryInfo = ({ showLocation, locationData, deliveryDate }) => {
    return (
        <Paper variant="outlined" sx={{ borderRadius: 2, width: "100%", p: 2, position: "relative" }}>
            <Grid container pb={3}>
                <Grid item xs={12}>
                    <Typography variant="h6" color={"primary"}>Delivery Information</Typography>
                    <IconButton
                        size="large"
                        color="inherit"
                        sx={{ position: "absolute", right: 10, top: 10, p: 1 }}
                        onClick={showLocation}
                    >
                        <EditIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container pb={2}>
                <Grid item xs={4}>
                    <Typography variant="body2" fontWeight={600}>Zip Code</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" fontWeight={600}>{locationData?.pincode}</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                    <Typography variant="body2" fontWeight={600}>Date</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" fontWeight={600}>{deliveryDate ? format(parseISO(deliveryDate), "dd/MM/yyyy") : "-"}</Typography>
                </Grid>
            </Grid>
        </Paper >
    )
}