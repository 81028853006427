import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setCartsData, setCartsItemCount } from "../../store/cart/cartSlice";

const mapStateToProps = (state) => {
    return {
        locationData: state.common.locationData,
        deliveryDate: state.common.deliveryDate,
        cartsData: state.cart.cartsData,
        cartsItemCount: state.cart.cartsItemCount,
    };
};

const mapDispatch = {
    setCartsData,
    setCartsItemCount
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(mapDispatch, dispatch);

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;