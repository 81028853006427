import React from "react";

// mui components
import CssBaseline from "@mui/material/CssBaseline";

// project imports
import SnackBarContainer from "./components/Common/Snackbar";
import { ThemeProvider, createTheme } from "@mui/material/styles";


// routing
import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import LightTheme from "./helpers/Theme";

import "./App.css";

// ==============================|| APP Component ||============================== //

function App() {

    const theme = createTheme(LightTheme);

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackBarContainer />
                <BrowserRouter>
                    <Routes />
                </BrowserRouter>
            </ThemeProvider>
        </>
    );
}

export default App;