import { Stack } from "@mui/material"
import { DeliveryInfo } from "./DeliveryInfo"
import { ShoppingInfo } from "./ShoppingInfo"

export const CartData = ({ showLocation, locationData, deliveryDate, add, remove, cartsData, navigate }) => {
console.log(cartsData, "cartsData")
    return (
        <Stack spacing={2} sx={{ height: "100%", pl: { xs: 0, md: 3 }, pr: { xs: 0, md: 3 } }}>
            <DeliveryInfo showLocation={showLocation} locationData={locationData} deliveryDate={deliveryDate} />

            <ShoppingInfo add={add} remove={remove} cartsData={cartsData} navigate={navigate} />
        </Stack>
    )
}