import * as yup from "yup";
import { Formik } from "formik";
import { useAddCustomerAddressMutation, useUpdateCustomerAddressMutation } from "../store/user/userApis";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../store/common/commonSlice";
import { Grid, Typography } from "@mui/material";
import Input from "./Common/Input";
import StyledTextarea from "./Common/StyledTextarea";
import Button from "./Common/Button";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const addressValidation = yup.object().shape({
    name: yup.string().required("Name is required"),
    mobile: yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Please enter mobile number"),
    address_line_1: yup.string().required("Address is required"),
    address_line_2: yup.string().required("Address is required"),
    city: yup.string().required("City is required"),
    pincode: yup.string().required("Pincode is required"),
});

export const AddUpdateAddressComponent = ({ defaultValue, show, close, setSelectedAddress = null }) => {
    const dispatch = useDispatch();
    const [addCustomerAddress] = useAddCustomerAddressMutation();
    const [updateCustomerAddress] = useUpdateCustomerAddressMutation();

    const submitHandler = async (values) => {
        if (values.id) {
            let payload = {
                id: values.id,
                name: values.name,
                mobile: values.mobile,
                address_line_1: values.address_line_1,
                address_line_2: values.address_line_2,
                city: values.city,
                pincode: values.pincode
            }
            updateCustomerAddress(payload).unwrap().then(fulfilled => {
                dispatch(setSnackBar({
                    open: true,
                    message: fulfilled?.message,
                    severity: "success",
                }))
                close();
            }).catch(rejected => dispatch(setSnackBar({
                open: true,
                message: rejected.message,
                severity: "error",
            })));
        } else {
            addCustomerAddress(values).unwrap().then(fulfilled => {
                dispatch(setSnackBar({
                    open: true,
                    message: fulfilled.message,
                    severity: "success",
                }))
                if (setSelectedAddress && fulfilled?.result) {
                    setSelectedAddress(fulfilled?.result?.id);
                }
            }).catch(rejected => dispatch(setSnackBar({
                open: true,
                message: rejected.message,
                severity: "error",
            })));
            if (close) {
                close();
            }
        }
    }

    return (
        <>
            <Formik
                initialValues={defaultValue || {}}
                onSubmit={(values) => submitHandler(values)}
                validationSchema={addressValidation}
            >
                {({
                    handleChange,
                    handleSubmit,
                    errors,
                    values
                }) => (
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <Typography variant="h6" fontWeight={700}>Personal Details</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    label="Name*"
                                    name={"name"}
                                    onChange={handleChange("name")}
                                    fullWidth
                                    error={errors.name && Boolean(errors.name)}
                                    helperText={errors.name}
                                    value={values?.name}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    label="Mobile*"
                                    name={"mobile"}
                                    onChange={handleChange("mobile")}
                                    fullWidth
                                    error={errors.mobile && Boolean(errors.mobile)}
                                    helperText={errors.mobile}
                                    value={values?.mobile}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <StyledTextarea
                                    label="Address Line*"
                                    name={"address_line_1"}
                                    onChange={handleChange("address_line_1")}
                                    fullWidth
                                    error={errors.address_line_1 && Boolean(errors.address_line_1)}
                                    helperText={errors.address_line_1}
                                    value={values?.address_line_1}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <StyledTextarea
                                    label="Address Line*"
                                    name={"address_line_2"}
                                    onChange={handleChange("address_line_2")}
                                    fullWidth
                                    error={errors.address_line_2 && Boolean(errors.address_line_2)}
                                    helperText={errors.address_line_2}
                                    value={values?.address_line_2}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    label="Pincode*"
                                    name={"pincode"}
                                    onChange={handleChange("pincode")}
                                    fullWidth
                                    error={errors.pincode && Boolean(errors.pincode)}
                                    helperText={errors.pincode}
                                    value={values?.pincode}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    label="City*"
                                    name={"city"}
                                    onChange={handleChange("city")}
                                    fullWidth
                                    error={errors.city && Boolean(errors.city)}
                                    helperText={errors.city}
                                    value={values?.city}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ justifyContent: "center" }} display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                <Button fullWidth={false} variant="contained" color="secondary" size="large" type="submit" onClick={handleSubmit}>Submit</Button>
                                <Button fullWidth={false} sx={{ ml: 2 }} variant="outlined" color="secondary" size="large" type="button" onClick={close}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </>
    )
}