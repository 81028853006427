import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";
import { cartDataParser } from './cartParser';

const cartApis = createApi({
    reducerPath: 'cartApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("token");
            if (token) {
                headers.set('Authorization', `BEARER ${token}`)
            }
            return headers;
        }
    }),
    tagTypes: ["CART"],
    endpoints: build => ({
        getCartData: build.query({
            query: (params) => ({
                url: `${baseUrl}/cart`,
                method: "GET",
                params: params,
            }),
            transformResponse: (response) => cartDataParser(response),
            providesTags: ['CART'],
        }),
        handleSingleCart: build.mutation({
            query: (payload) => ({
                url: `${baseUrl}/cart`,
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['CART'],
        }),
        handleMultipleCart: build.mutation({
            query: (payload) => ({
                url: `${baseUrl}/cart/multiple`,
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['CART'],
        }),
        clearCart: build.mutation({
            query: (params) => ({
                url: `${baseUrl}/cart/clear-cart`,
                method: "GET",
                params: params
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.data,
            invalidatesTags: ['CART'],
        }),
        applyTip: build.mutation({
            query: (body) => ({
                url: `${baseUrl}/cart/add-tip`,
                method: "POST",
                body: body
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.data,
            invalidatesTags: ['CART'],
        }),
    }),
});

export const { useGetCartDataQuery, useHandleSingleCartMutation, useHandleMultipleCartMutation, useClearCartMutation, useApplyTipMutation } = cartApis;

export default cartApis;