import { createSlice } from "@reduxjs/toolkit";
import { getCookie } from "../../helpers/cookies";

const initialState = {
    sidebar_open: true,
    snackbar: {
        open: false,
        message: "",
        severity: "info",
    },
    authentication_loading: false,
    locationData: getCookie("locationData") ? JSON.parse(getCookie("locationData")) : null,
    deliveryDate: getCookie("deliveryDate") || null
};

export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setSidebarMenu: (state, action) => {
            state.sidebar_open = action.payload;
        },
        setSnackBar: (state, action) => {
            state.snackbar = action.payload;
        },
        setAuthenticationLoading: (state, action) => {
            state.authentication_loading = action.payload.state;
        },
        setLocationData: (state, action) => {
            state.locationData = action.payload;
        },
        setDeliveryDate: (state, action) => {
            state.deliveryDate = action.payload;
        }
    },
    extraReducers: (builder) => {
    }
});

// Action creators are generated for each case reducer function
export const { setAuthenticationLoading, setSnackBar, setSidebarMenu, setLocationData, setDeliveryDate } = commonSlice.actions;

export default commonSlice;
