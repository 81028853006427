import React from "react";
import Skeleton from "@mui/material/Skeleton";

const CategorySkeleton = (props) => {

    return (
        <>
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
            <Skeleton animation="wave" height={60} />
        </>
    );
};

export default CategorySkeleton;
