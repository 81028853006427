import { Box, Button, Divider, Grid, IconButton, Paper, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ClearIcon from '@mui/icons-material/Clear';
import { Fragment } from "react";
import { EmptyCartIcon } from "../assets/Icons";
import { useHandleMultipleCartMutation } from "../store/cart/cartApis";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../store/common/commonSlice";
import { isAuth, setCookie } from "../helpers/cookies";

export const ShoppingInfo = ({ add, remove, cartsData, navigate }) => {
    const dispatch = useDispatch();
    const [handleMultipleCart] = useHandleMultipleCartMutation();

    const handleCheckount = async () => {
        if (!isAuth()) {
            navigate("/login");
            return false;
        }
        handleMultipleCart({ products: cartsData }).unwrap().then(fulfilled => {
            navigate("/carts");
        }).catch(rejected => dispatch(setSnackBar({
            open: true,
            message: rejected.message,
            severity: "error",
        })));
    }

    return (
        <Paper variant="outlined" sx={{ borderRadius: 2, width: "100%", height: "73vh", p: 2, position: "relative", display: "flex", flexDirection: "column", flexWrap: "nowrap", justifyContent: "space-between" }}>
            <Grid container pb={3}>
                <Grid item xs={12}>
                    <Typography variant="h6">Shopping Cart</Typography>
                    {
                        cartsData.length > 0
                            ?
                            <IconButton
                                size="large"
                                color="inherit"
                                sx={{ position: "absolute", right: 10, top: 10, p: 1 }}
                                onClick={() => remove(false)}
                            >
                                <ClearIcon titleAccess="Clear Cart" />
                            </IconButton>
                            :
                            null
                    }
                </Grid>
            </Grid>
            {
                cartsData.length > 0
                    ?
                    <>
                        <Grid container pb={3} sx={{ overflow: "auto", height: "100%" }}>
                            <Grid item xs={12}>
                                {
                                    cartsData.length > 0 && cartsData.map((val, ind) => {
                                        return (
                                            <Fragment key={ind}>
                                                <Grid container pb={2} pt={2} position={"relative"}>
                                                    <Grid item xs={1.5} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                                        <IconButton onClick={() => add({ id: val.product_id })} sx={{ p: "2px", border: "1px solid" }} size="small" color="secondary"><AddIcon /></IconButton>
                                                        <Typography variant="BUTTON">{val.qty}</Typography>
                                                        <IconButton onClick={() => remove(val.product_id)} sx={{ p: "2px", border: "1px solid" }} size="small" color="secondary"><RemoveIcon /></IconButton>
                                                    </Grid>
                                                    <Grid item xs={2.5} sx={{ width: "100%", height: "100%", borderRadius: 2, alignSelf: "center" }}>
                                                        <Box
                                                            component="img"
                                                            sx={{ width: "100%", height: "100%", borderRadius: 2 }}
                                                            alt="Product"
                                                            src={val.image}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8} pl={2} display={"flex"} flexDirection={"column"} justifyContent={"space-around"}>
                                                        <Typography variant="body2" fontWeight={500} sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{val.name}</Typography>
                                                        <Typography variant="caption">${val.price} X {val.qty}</Typography>
                                                        <Typography variant="subtitle2" fontWeight={700} color={"secondary"}>${parseFloat(val.price * val.qty).toFixed(2)}</Typography>
                                                    </Grid>
                                                    <Box sx={{ position: "absolute", top: 45, right: 0 }}>
                                                        <IconButton
                                                            size="large"
                                                            color="inherit"
                                                            sx={{ p: 1 }}
                                                            onClick={() => remove(val.product_id, true)}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Grid>
                                                <Divider />
                                            </Fragment>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                        <Grid container pb={3}>
                            <Grid item xs={12}>
                                <Button onClick={() => handleCheckount()} fullWidth color="secondary" variant="contained">Checkout Now</Button>
                            </Grid>
                        </Grid>
                    </>
                    :
                    <Grid container pb={3} sx={{ overflow: "auto", height: "100%" }}>
                        <Grid item xs={12} alignSelf={"center"} textAlign={"center"}>
                            <EmptyCartIcon />
                            <Typography variant="h6">Your cart is empty</Typography>
                            <Typography variant="body2">Please add some items from the menu</Typography>
                        </Grid>
                    </Grid>
            }
        </Paper >
    )
}