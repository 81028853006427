import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cartsData: [],
    cartsItemCount: 0,
    add_to_cart_loading: true,
    api_cart_response: []
};

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        setCartsData: (state, action) => {
            state.cartsData = action.payload;
        },
        setCartsItemCount: (state, action) => {
            state.cartsItemCount = action.payload;
        },
    },
    extraReducers: (builder) => {
    }
});

// Action creators are generated for each case reducer function
export const { setCartsData, setCartsItemCount } = cartSlice.actions;

export default cartSlice;
