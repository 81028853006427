import { useDeleteCustomerAddressMutation, useGetCustomerAddressQuery, useGetUserProfileQuery, useUpdateCustomerAddressMutation } from "../store/user/userApis";
import { Box, Card, CardActions, CardContent, Grid, IconButton, Typography } from "@mui/material";
import Button from "./Common/Button";
import CategorySkeleton from "./Common/CategorySkeleton";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../store/common/commonSlice";
import { AddUpdateAddressComponent } from "./AddUpdateAddressComponent";

export const AddressComponent = ({ show, locationData }) => {

    const [defaultValue, setDefaultValue] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const dispatch = useDispatch();

    const { data: userData } = useGetUserProfileQuery();
    const { isLoading, data } = useGetCustomerAddressQuery({}, { skip: !show });
    const [deleteCustomerAddress] = useDeleteCustomerAddressMutation();
    const [updateCustomerAddress] = useUpdateCustomerAddressMutation();

    useEffect(() => {
        if (userData?.result)
            setDefaultValue({
                name: userData.result.name,
                mobile: userData.result.mobile,
                pincode: locationData?.pincode
            })
    }, [userData, locationData]);

    const handleDelete = async (id) => {
        deleteCustomerAddress({ id }).unwrap().then(fulfilled => {
            console.log(fulfilled, "fulfilled");
            dispatch(setSnackBar({
                open: true,
                message: fulfilled.message,
                severity: "success",
            }))
        }).catch(rejected => dispatch(setSnackBar({
            open: true,
            message: rejected.message,
            severity: "error",
        })));
    }

    const handleCloseAction = () => {
        setShowForm(false);
        setDefaultValue({
            name: userData.result.name,
            mobile: userData.result.mobile,
            pincode: locationData?.pincode
        })
    }

    const handleOpenAction = (val) => {
        setDefaultValue(val);
        setShowForm(true);
    }

    const setAsDefault = async (values) => {
        let payload = {
            id: values.id,
            name: values.name,
            mobile: values.mobile,
            address_line_1: values.address_line_1,
            address_line_2: values.address_line_2,
            city: values.city,
            pincode: values.pincode,
            set_as_default: "1"
        }
        updateCustomerAddress(payload).unwrap().then(fulfilled => {
            dispatch(setSnackBar({
                open: true,
                message: fulfilled?.message,
                severity: "success",
            }))
        }).catch(rejected => dispatch(setSnackBar({
            open: true,
            message: rejected.message,
            severity: "error",
        })));
    }

    return (
        <>
            {
                isLoading
                    ?
                    <CategorySkeleton />
                    :
                    <Grid container sx={{ display: "flex" }} direction={"column"} width={"100%"} height={"100%"} >
                        <Grid container flex={0} px={2} py={1} borderBottom={1} justifyContent={"space-between"} borderColor={"rgba(5, 5, 5, 0.06)"}>
                            <Grid item alignSelf={"center"}>
                                <Typography variant="h6">My Addresses</Typography>
                            </Grid>
                            <Box>
                                <Button variant="outlined" color="secondary" endIcon={<AddIcon />} onClick={() => setShowForm(true)}>
                                    Add New Address
                                </Button>
                            </Box>
                        </Grid>
                        <Grid flex={1} px={2} py={5} overflow={"auto"}>
                            <Grid container spacing={2} >
                                {
                                    data?.result?.result && data?.result?.result?.length > 0 && !showForm ?
                                        data?.result?.result.map((val, index) => {
                                            return (
                                                <Grid item xs={4} key={index}>
                                                    <Card
                                                        sx={{ width: "100%", height: "100%", border: "1px solid", borderColor: "rgba(5, 5, 5, 0.06)" }}
                                                    >
                                                        <CardActions sx={{ justifyContent: "center" }}>
                                                            <IconButton size="large" color="secondary" onClick={() => handleOpenAction(val)}>
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton size="large" color="secondary" onClick={() => handleDelete(val.id)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                            {
                                                                !val?.set_as_default
                                                                    ?
                                                                    <Button variant="text" color="secondary" onClick={() => setAsDefault(val)}>Set as default</Button>
                                                                    :
                                                                    null
                                                            }

                                                        </CardActions>
                                                        <CardContent>
                                                            <Box>
                                                                <Typography variant="body2">Name: <strong>{val.name}</strong></Typography>
                                                                <Typography variant="body2">Mobile: <strong>{val.mobile}</strong></Typography>
                                                                <Typography variant="body2">Address Line 1: <strong>{val.address_line_1}</strong></Typography>
                                                                <Typography variant="body2">Address Line 2: <strong>{val.address_line_2}</strong></Typography>
                                                                <Typography variant="body2">City: <strong>{val.city}</strong></Typography>
                                                                <Typography variant="body2">Pincode: <strong>{val.pincode}</strong></Typography>
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            )
                                        })
                                        :
                                        <AddUpdateAddressComponent show={showForm} defaultValue={defaultValue} close={handleCloseAction} />
                                }
                            </Grid>
                        </Grid>
                    </Grid >
            }
        </>
    )
}