import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { Grid, TextField } from '@mui/material';
import { generateDays } from '../helpers/utils';
import { format } from "date-fns";
import { setCookie } from '../helpers/cookies';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function LocationModel({ show, hide, getLocationList, locationData, setLocationData, deliveryDate, setDeliveryDate }) {

    const generatedDays = generateDays();

    const handleLocationInput = async (val) => {
        let valid = /^[0-9]{1,6}$/.test(val);

        if (valid && val.length === 6) {
            let result = await getLocationList({ keywords: val });
            if (result.data?.success && result.data?.result?.result.length > 0) {
                setLocationData(result.data?.result?.result[0]);
                setCookie("locationData", JSON.stringify(result.data?.result?.result[0]));
            }
        } else {
            setLocationData(null);
        }
    }

    const handleDeliveryDate = (e, newVal) => {
        setDeliveryDate(newVal);
        setCookie("deliveryDate", newVal);
    }

    return (
        <div>
            <BootstrapDialog
                onClose={hide}
                aria-labelledby="customized-dialog-title"
                open={show}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={hide}>
                    Select Location
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                label="Delivery Pincode"
                                size='small'
                                variant='outlined'
                                onKeyUp={(e) => handleLocationInput(e.target.value)}
                                defaultValue={locationData?.pincode || ""}
                            />
                        </Grid>
                        {
                            locationData
                                ?
                                <Grid item xs={12} pt={1}>
                                    <Typography variant='subtitle2'>{locationData?.address_1}</Typography>
                                    <Typography variant='subtitle2'>{locationData?.address_2}</Typography>
                                    <Typography variant='subtitle2'>{locationData?.pincode}</Typography>
                                </Grid>
                                :
                                null
                        }
                    </Grid>
                    <Grid container spacing={2} pt={2}>
                        <Grid item xs={12} md={12}>
                            <Typography variant='h6'>Delivery Date</Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <ToggleButtonGroup
                                color="secondary"
                                exclusive
                                aria-label="web0"
                                value={deliveryDate || ""}
                                onChange={handleDeliveryDate}

                            >
                                {
                                    generatedDays.map((val, ind) => {
                                        return (
                                            <ToggleButton key={ind} value={format(val, "yyyy-MM-dd")} sx={{ display: "block", p: 2 }}>
                                                <Typography variant='body1' fontWeight={"600"}>{format(val, "dd/MM")}</Typography>
                                                <Typography variant='subtitle2'>{format(val, "EEE")}</Typography>
                                            </ToggleButton>
                                        )
                                    })
                                }
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                </DialogContent>
                {
                    locationData && deliveryDate
                        ?
                        <DialogActions >
                            <Button color='secondary' onClick={hide} variant='outlined'>
                                Continue
                            </Button>
                        </DialogActions>
                        :
                        null
                }

            </BootstrapDialog>
        </div>
    );
}