import { get } from "lodash-es";

export const productParser = (response) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }
        console.log(response, "response");

        return {
            id: get(response, "id", ""),
            cuisine: get(response, "cuisine", ""),
            name: get(response, "name", ""),
            category_id: get(response, "category_id", ""),
            image: get(response, "image", ""),
            description: get(response, "description", ""),
            price: get(response, "price", ""),
            veg: get(response, "veg", ""),
            display_order: get(response, "display_order", ""),
            status: get(response, "status", ""),
            is_deleted: get(response, "is_deleted", ""),
            spice_evel: get(response, "spice_evel", ""),
            best_before: get(response, "best_before", ""),
            serving: get(response, "serving", ""),
            ingredients: get(response, "ingredients", []),
            nutrition_values: JSON.parse(response?.nutrition_values),
            allergies: get(response, "allergies", ""),
            createdAt: get(response, "createdAt", ""),
            updatedAt: get(response, "updatedAt", ""),
            category_name: response["category.name"]
        }

    } catch (error) {
        throw new Error(error);
    }
}
