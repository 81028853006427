import { Button, FormControl, Grid, InputLabel, OutlinedInput, Paper, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useApplyTipMutation } from "../store/cart/cartApis";
import { setSnackBar } from "../store/common/commonSlice";

export const TipInfo = ({ cartsData }) => {
    const [tipAmount, setTipAmount] = useState(0);

    const dispatch = useDispatch();
    const [handleApplyTip] = useApplyTipMutation();

    useEffect(() => {
        const getData = setTimeout(() => {
            applyTip();
        }, 1000)

        return () => clearTimeout(getData)
    }, [tipAmount]);

    const applyTip = () => {
        handleApplyTip({ tip: tipAmount, cart_id: cartsData[0].cart_id }).unwrap().then(fulfilled => {
        }).catch(rejected => dispatch(setSnackBar({
            open: true,
            message: rejected.message,
            severity: "error"
        })));
    }
    return (
        <Paper variant="outlined" sx={{ borderRadius: 2, width: "100%", p: 2, mt: 2, position: "relative" }}>
            <Grid container pb={3}>
                <Grid item xs={12} pb={3}>
                    <Typography variant="h6" color={"primary"}>Add Tip</Typography>
                </Grid>
                <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
                    <Button color="secondary" variant={tipAmount === 5 ? "contained" : "outlined"} onClick={() => setTipAmount(5)}>5</Button>
                    <Button color="secondary" variant={tipAmount === 10 ? "contained" : "outlined"} onClick={() => setTipAmount(10)}>10</Button>
                    <Button color="secondary" variant={tipAmount === 15 ? "contained" : "outlined"} onClick={() => setTipAmount(15)}>15</Button>
                    <Button color="secondary" variant={tipAmount === 20 ? "contained" : "outlined"} onClick={() => setTipAmount(20)}>20</Button>
                    <Button color="secondary" variant={tipAmount === 25 ? "contained" : "outlined"} onClick={() => setTipAmount(25)}>25</Button>
                </Grid>
                <Grid item xs={12} pt={2}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel htmlFor="button">Custom Tip</InputLabel>
                        <OutlinedInput
                            id="button"
                            type={'text'}
                            value={tipAmount}
                            onChange={(e) => setTipAmount(e.target.value ? parseInt(e.target.value) : 0)}
                            label="Custom Tip"
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Paper >
    )
}