import { Alert, Box, Grid, Paper, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { useGetCustomerAddressQuery } from "../store/user/userApis";
import StyledTextarea from "./Common/StyledTextarea";
import { AddUpdateAddressComponent } from "./AddUpdateAddressComponent";


function addressLabel(address) {
    return (
        <Box>
            <Typography variant="body2">Name: {address.name}</Typography>
            <Typography variant="body2">Mobile: {address.mobile}</Typography>
            <Typography variant="body2">Address Line: {address.address_line_1} {address.address_line_2} </Typography>
            <Typography variant="body2">Pincode: {address.pincode} </Typography>
            <Typography variant="body2">City: {address.city} </Typography>
        </Box>
    )
}

export const DeliveryDetailsComponent = ({ show, selectedAddress, setSelectedAddress, instruction, setInstruction, pincode }) => {
    const { isLoading, data } = useGetCustomerAddressQuery();

    const handleChange = (event, newAlignment) => {
        setSelectedAddress(newAlignment);
    };


    return (
        <Grid item xs={6} sx={{ display: show ? "block" : "none" }}>
            <Paper sx={{ p: 3 }}>
                <Grid container pb={3} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" fontWeight={700}>Select Address</Typography>
                    </Grid>

                    {
                        selectedAddress === "new" ?
                            <Grid item xs={12} md={12}>
                                <Alert severity="error">Please select any one address from below list or create new address before continue</Alert>
                            </Grid>
                            : null
                    }

                    <Grid item xs={12} md={12}>
                        <ToggleButtonGroup
                            color="primary"
                            value={selectedAddress}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                            orientation="vertical"
                            fullWidth
                        >
                            {
                                !isLoading && data?.result?.result.length > 0 ? data?.result?.result?.map((val, key) => {
                                    if (val.set_as_default)
                                        setSelectedAddress(val.id);
                                    return (
                                        <ToggleButton key={key} sx={{ display: "block", textAlign: "left" }} color="secondary" value={val.id}> {addressLabel(val)}</ToggleButton>
                                    )
                                }) : null
                            }
                            <ToggleButton sx={{ display: "block", textAlign: "left" }} value="new" color="secondary">New Address</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
                <Grid container pb={3}>
                    {
                        selectedAddress === "new"
                            ?
                            <AddUpdateAddressComponent defaultValue={{ pincode }} setSelectedAddress={(val) => setSelectedAddress(val)} />
                            :
                            null
                    }
                </Grid>

                <Grid container pb={3} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" fontWeight={700}>Delivery Instructions</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledTextarea
                            placeholder="Eg: Do not ring the bell."
                            name={"delivery_instrucations"}
                            minRows={3}
                            fullWidth
                            value={instruction}
                            onChange={(e) => setInstruction(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </Paper >
        </Grid >
    )
}