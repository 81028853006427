export const EmptyCartIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="196.287" height="161.228" viewBox="0 0 196.287 161.228">
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="rgb(210, 63, 87)" />
                    <stop offset="1" stopColor="#ce7672" />
                </linearGradient>
            </defs>
            <g id="Group_127" data-name="Group 127" transform="translate(-893.779 -127.931)">
                <g id="Ellipse_83" data-name="Ellipse 83" transform="translate(913 144)" fill="none" stroke="#292e40" strokeWidth="5" opacity="0.2">
                    <circle cx="71.5" cy="71.5" r="71.5" stroke="none" />
                    <circle cx="71.5" cy="71.5" r="69" fill="none" />
                </g>
                <g id="Group_125" data-name="Group 125" transform="translate(90.296 61)">
                    <g id="Group_87" data-name="Group 87" transform="translate(852.704 120)">
                        <path id="Path_452" data-name="Path 452" d="M150.15,240.62a2.177,2.177,0,0,0,2.177,2.177h5.9a2.176,2.176,0,0,0,0-4.353h-5.9A2.177,2.177,0,0,0,150.15,240.62Z" transform="translate(-137.091 -209.688)" fill="url(#linear-gradient)" />
                        <path id="Path_453" data-name="Path 453" d="M127.954,240.62a2.176,2.176,0,0,0-2.176-2.176h-5.9a2.176,2.176,0,1,0,0,4.353h5.9A2.176,2.176,0,0,0,127.954,240.62Z" transform="translate(-117.704 -209.688)" fill="url(#linear-gradient)" />
                        <path id="Path_454" data-name="Path 454" d="M152.192,281.26h-2.676a2.176,2.176,0,0,0,0,4.353h2.676a2.176,2.176,0,0,0,0-4.353Z" transform="translate(-135.412 -235.271)" fill="url(#linear-gradient)" />
                        <path id="Path_455" data-name="Path 455" d="M157.1,181.157l-.221-.221a7.042,7.042,0,0,1-1.929-5.69,7.551,7.551,0,0,0-2.4-6.262A7.572,7.572,0,0,0,142.06,179.9a7.549,7.549,0,0,0,6.085,2.18,6.913,6.913,0,0,1,5.616,1.891l.251.251a1.665,1.665,0,0,1,.464.9h0a1.663,1.663,0,0,1-1.64,1.94h-2.394a2.259,2.259,0,0,0-2.3,2.06,2.176,2.176,0,0,0,2.173,2.293h3.452a2.093,2.093,0,0,1,2.064,1.744l1.5,8.846a2.094,2.094,0,0,1-2.064,2.443h-6.822a2.259,2.259,0,0,0-2.3,2.06,2.176,2.176,0,0,0,2.173,2.293h8.392a2.093,2.093,0,0,1,2.064,1.742h0a2.093,2.093,0,0,1-2.064,2.446h-2.359a2.26,2.26,0,0,0-2.3,2.06,2.177,2.177,0,0,0,2.173,2.293h44.191a5.764,5.764,0,0,0,5.482-3.984l9.96-30.564H161.055A5.591,5.591,0,0,1,157.1,181.157Zm-7.539-4.461a3.038,3.038,0,1,1,0-4.3A3.038,3.038,0,0,1,149.563,176.7Zm22.022,10.438.752,10.758h-11.3l-1.819-10.758ZM164.8,213a1.421,1.421,0,0,1-1.4-1.187l-1.619-9.571h10.868L173.394,213Zm21.948,0h-8.987L177,202.244H187.5Zm1.057-15.111H176.7l-.752-10.758h12.605Zm11.978,14.126a1.421,1.421,0,0,1-1.355.985h-7.316l.752-10.758h11.092Zm-7.614-14.126.753-10.758h14.942l-3.493,10.758Z" transform="translate(-130.932 -167)" fill="url(#linear-gradient)" />
                        <path id="Path_456" data-name="Path 456" d="M145.95,216.847a2.176,2.176,0,1,0,2.176,2.176A2.176,2.176,0,0,0,145.95,216.847Z" transform="translate(-133.281 -196.784)" fill="url(#linear-gradient)" />
                        <path id="Path_457" data-name="Path 457" d="M142.757,262.219a2.176,2.176,0,1,0-2.177,2.176A2.176,2.176,0,0,0,142.757,262.219Z" transform="translate(-130.072 -222.593)" fill="url(#linear-gradient)" />
                    </g>
                    <path id="Path_458" data-name="Path 458" d="M228.4,308.262a7.477,7.477,0,0,1-12.211,6.075,15.6,15.6,0,0,0-9.88-3.646h-1.457a15.6,15.6,0,0,0-9.88,3.646,7.477,7.477,0,1,1-.038-11.6A16.011,16.011,0,0,0,205,306.418h1.171a16.011,16.011,0,0,0,10.061-3.677,7.477,7.477,0,0,1,12.174,5.521Zm-38.169-3.4a3.69,3.69,0,1,0,3.69,3.69A3.69,3.69,0,0,0,190.235,304.864Zm30.7,0a3.69,3.69,0,1,0,3.69,3.69A3.69,3.69,0,0,0,220.933,304.864Z" transform="translate(696.13 -127.112)" fill="#292e40" />
                </g>
                <g id="circle" transform="translate(894.845 129.391)">
                    <path id="Stroke_1" data-name="Stroke 1" d="M0,0V1.663" transform="translate(5.719 72.606)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                    <g id="Group_8" data-name="Group 8" transform="translate(0.395 73.506)">
                        <path id="Stroke_2" data-name="Stroke 2" d="M0,0,1.359,1.4" transform="translate(1.589 0)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                        <path id="Stroke_4" data-name="Stroke 4" d="M0,0H1.921" transform="translate(0 3.723)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                        <path id="Stroke_6" data-name="Stroke 6" d="M0,1.4,1.359,0" transform="translate(1.434 6.114)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                    </g>
                    <path id="Stroke_9" data-name="Stroke 9" d="M0,1.663V0" transform="translate(5.719 79.811)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                    <path id="Stroke_10" data-name="Stroke 10" d="M1.108,1.108,0,0" transform="translate(7.381 79.256)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                    <path id="Stroke_11" data-name="Stroke 11" d="M1.663,0H0" transform="translate(9.044 77.594)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                    <path id="Stroke_12" data-name="Stroke 12" d="M1.108,0,0,1.108" transform="translate(8.49 73.714)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                    <path id="Stroke_13" data-name="Stroke 13" d="M0,0V1.108" transform="translate(94.951)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                    <path id="Stroke_15" data-name="Stroke 15" d="M0,0H1.108" transform="translate(91.626 3.325)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                    <path id="Stroke_16" data-name="Stroke 16" d="M0,1.108V0" transform="translate(94.951 5.542)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                    <path id="Stroke_17" data-name="Stroke 17" d="M1.108,0H0" transform="translate(96.614 3.325)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                    <path id="Stroke_18" data-name="Stroke 18" d="M0,0V1.663" transform="translate(30.659 46.556)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                    <path id="Stroke_19" data-name="Stroke 19" d="M0,0H1.663" transform="translate(25.671 51.544)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                    <path id="Stroke_20" data-name="Stroke 20" d="M0,1.663V0" transform="translate(30.659 54.316)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                    <path id="Stroke_21" data-name="Stroke 21" d="M1.663,0H0" transform="translate(33.431 51.544)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                    <g id="Group_42" data-name="Group 42" transform="translate(14.738 15.339)">
                        <path id="Stroke_22" data-name="Stroke 22" d="M2.4,0A2.4,2.4,0,1,1,0,2.4" transform="translate(132.826 91.651)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                        <path id="Stroke_24" data-name="Stroke 24" d="M7.528,3.208A3.783,3.783,0,1,1,3.246,0" transform="translate(138.646 119.985)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                        <path id="Fill_26" data-name="Fill 26" d="M0,4.06A6.994,6.994,0,0,0,4.113,0c.458,1.657,2.379,3.616,4.113,3.764A6.839,6.839,0,0,0,4.113,8.12C3.911,6.477,1.331,4.3,0,4.06" transform="translate(146.203 65.823)" fill="#ebecee" />
                        <path id="Fill_28" data-name="Fill 28" d="M0,2.03A3.5,3.5,0,0,0,2.056,0,2.811,2.811,0,0,0,4.112,1.882,3.419,3.419,0,0,0,2.056,4.06,3.271,3.271,0,0,0,0,2.03" transform="translate(0 88.882)" fill="#ebecee" />
                        <path id="Fill_30" data-name="Fill 30" d="M3.72,1.86A1.86,1.86,0,1,1,1.86,0,1.86,1.86,0,0,1,3.72,1.86" transform="translate(9.5 11.952)" fill="#d1d8df" />
                        <path id="Fill_32" data-name="Fill 32" d="M6.218,3.11A3.109,3.109,0,1,1,3.109,0,3.109,3.109,0,0,1,6.218,3.11" transform="translate(116.842 127.515)" fill="#ebecee" />
                        <path id="Fill_34" data-name="Fill 34" d="M4.932,2.467A2.466,2.466,0,1,1,2.466,0,2.466,2.466,0,0,1,4.932,2.467" transform="translate(175.552 7.917)" fill="#ebecee" />
                        <path id="Stroke_36" data-name="Stroke 36" d="M3.109,1.555A1.554,1.554,0,1,1,1.554,0,1.555,1.555,0,0,1,3.109,1.555Z" transform="translate(44.873 124.032)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                        <path id="Stroke_38" data-name="Stroke 38" d="M3.72,1.86A1.86,1.86,0,1,1,1.86,0,1.86,1.86,0,0,1,3.72,1.86Z" transform="translate(43.336 0)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                        <path id="Stroke_40" data-name="Stroke 40" d="M3.122,4.456A2.3,2.3,0,1,1,4.455,1.485,2.3,2.3,0,0,1,3.122,4.456Z" transform="translate(91.379 138.35)" fill="none" stroke="#d2d8df" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.921" />
                    </g>
                </g>
                <g id="Group_126" data-name="Group 126" transform="translate(140 -101)">
                    <path id="Fill_94" data-name="Fill 94" d="M16.042,6.262a12.058,12.058,0,0,1-6-4.622L9,0,8.173,1.391a11.992,11.992,0,0,1-6.3,4.927L0,7l1.929.754A12.23,12.23,0,0,1,7.44,11.845L9,14l1.2-1.763a11.968,11.968,0,0,1,5.261-4.154L18,7Z" transform="translate(857 253.5)" fill="#e8e9eb" />
                    <path id="Fill_95" data-name="Fill 95" d="M5.347,1.789a3.99,3.99,0,0,1-2-1.321L3,0,2.724.4a3.957,3.957,0,0,1-2.1,1.408L0,2l.643.215A4.083,4.083,0,0,1,2.48,3.384L3,4l.4-.5A3.941,3.941,0,0,1,5.153,2.31L6,2Z" transform="translate(875 246.5)" fill="#e8e9eb" />
                    <path id="Fill_96" data-name="Fill 96" d="M5.347,1.789a3.989,3.989,0,0,1-2-1.321L3,0,2.724.4a3.957,3.957,0,0,1-2.1,1.408L0,2l.644.216A4.08,4.08,0,0,1,2.48,3.384L3,4l.4-.5A3.944,3.944,0,0,1,5.153,2.31L6,2Z" transform="translate(854 251.5)" fill="#e8e9eb" />
                    <path id="Stroke_97" data-name="Stroke 97" d="M4.482,0C-4.927,10.322,2.389,17.827,9,20" transform="translate(856.931 267.647) rotate(16)" fill="none" stroke="#e8e9eb" strokeMiterlimit="10" strokeWidth="1" />
                </g>
                <path id="Stroke_30" data-name="Stroke 30" d="M42,21.927a4.4,4.4,0,0,1-2.757-.379c-1.947-1.235-1.222-2.866-2.7-3.607-1.839-.923-2.793.976-4.555.093-2.071-1.039-.706-2.732-2.661-3.713-2.047-1.026-3.55.619-5.479-.349-1-.5-1.134-1-1.562-1.929-.462-1.005-.373-1.88-1.635-2.509-1.661-.827-2.895.926-4.941-.1-1.955-.981-.59-2.674-2.661-3.713-1.762-.884-2.716,1.015-4.555.093-1.479-.742-.7-2.452-2.7-3.608C4.155,1.261,2.847,2.119,2,2,.527,1.778,0,0,0,0" transform="translate(1035 207.5)" fill="#f79420" stroke="#f79420" strokeMiterlimit="10" strokeWidth="1" />
                <path id="Stroke_31" data-name="Stroke 31" d="M0,35a4.277,4.277,0,0,1,1.138-2.6c1.8-1.56,3.288-.384,4.455-1.626,1.45-1.543-.252-3.02,1.137-4.5,1.633-1.738,3.011.084,4.552-1.557C12.9,23,11.6,21.058,13.126,19.439a3.18,3.18,0,0,1,2.413-.985c1.163-.168,2.041.17,3.033-.891,1.3-1.4-.172-3.107,1.441-4.824,1.542-1.641,2.92.181,4.552-1.557,1.389-1.48-.312-2.956,1.137-4.5,1.167-1.242,2.72.007,4.455-1.627,1.417-1.335.885-2.864,1.241-3.657C32.022.011,34,0,34,0" transform="translate(919 250.5)" fill="none" stroke="#f79420" strokeMiterlimit="10" strokeWidth="1" />
            </g>
        </svg>

    )
}