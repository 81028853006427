import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";

// all api calling using rtk query
import commonApis from "./common/commonApis";
import cartApis from "./cart/cartApis";
import userApis from "./user/userApis";
import orderApis from "./order/orderApis";

// redux state slices
import commonSlice from "./common/commonSlice";
import cartSlice from "./cart/cartSlice";
import userSlice from "./user/userSlice";

// axios interceptor
import axiosInterceptor from "../helpers/axios";

const reducers = {
    [commonSlice.name]: commonSlice.reducer,
    [cartSlice.name]: cartSlice.reducer,
    [userSlice.name]: userSlice.reducer,

    [commonApis.reducerPath]: commonApis.reducer,
    [cartApis.reducerPath]: cartApis.reducer,
    [userApis.reducerPath]: userApis.reducer,
    [orderApis.reducerPath]: orderApis.reducer,
};

const initialState = {};

const rootReducer = combineReducers(reducers);

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: false
        }).concat([
            commonApis.middleware,
            cartApis.middleware,
            userApis.middleware,
            orderApis.middleware,
        ]);
    },
    devTools: true,
    preloadedState: initialState,
    enhancers: (defaultEnhancers) => [...defaultEnhancers]
});


export const useAppSelector = () => useSelector(rootReducer);
export const useAppDispatch = () => useDispatch(store.dispatch);

axiosInterceptor(store.dispatch);

export default store;