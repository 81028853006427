import { useTheme, withTheme } from "@emotion/react";
import { Box, Grid, Paper, Stack, Tab, Tabs, useMediaQuery } from "@mui/material";
import React from "react";
import { ProfileComponent } from "../../components/ProfileComponent";
import { MyOrdersComponent } from "../../components/MyOrdersComponent";
import { AddressComponent } from "../../components/AddressComponent";
import { signout } from "../../helpers/cookies";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ width: "100%" }}
        >
            {children}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const ProfilePageContainer = (props) => {
    const theme = useTheme();
    const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Stack >
            <Grid container alignContent={"flex-start"} justifyContent={"center"}>
                <Grid item md={8} xs={12} >
                    <Paper variant="outlined" sx={{ borderRadius: 2, width: "100%" }}>
                        <Box
                            sx={{ flexGrow: 1, display: { md: 'flex', xs: "block" }, height: { md: "100%", xs: "auto" } }}
                        >
                            <Tabs
                                orientation={greaterThanMid ? "vertical" : "horizontal"}
                                variant="scrollable"
                                value={value}
                                textColor="secondary"
                                indicatorColor="secondary"
                                onChange={handleChange}
                                sx={{ borderRight: 1, borderColor: 'divider', minWidth: 150 }}
                            >
                                <Tab label="Profile" {...a11yProps(0)} sx={{ borderBottom: "1px solid", borderColor: "rgba(5, 5, 5, 0.06)" }} />
                                <Tab label="Addresses" {...a11yProps(1)} sx={{ borderBottom: "1px solid", borderColor: "rgba(5, 5, 5, 0.06)" }} />
                                <Tab label="My Orders" {...a11yProps(2)} sx={{ borderBottom: "1px solid", borderColor: "rgba(5, 5, 5, 0.06)" }} />
                                <Tab label="Logout" onClick={() => signout(() => window.location.reload())} sx={{ borderBottom: "1px solid", borderColor: "rgba(5, 5, 5, 0.06)" }} />
                                <Tab label="Go to Home" onClick={() => props.navigate("/")} sx={{ borderBottom: "1px solid", borderColor: "rgba(5, 5, 5, 0.06)" }} />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <ProfileComponent show={value === 0} />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <AddressComponent show={value === 1} locationData={props.locationData} />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <MyOrdersComponent show={value === 2} navigate={props.navigate} />
                            </TabPanel>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Stack >
    );
}
export default withTheme(ProfilePageContainer);
