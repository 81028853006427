// export const baseUrl = "http://localhost:7057"
export const baseUrl = "https://api.grammyfoods.com"; //live


export const drawerWidth = 240;

export const OrderStatus = {
    0: "Order Placed",
    1: "Order Accepted",
    2: "Order Ready for Delivery",
    3: "Order Out of Delivery",
    4: "Order Delivered",
    5: "Order Cancelled"
}

export const OrderStatusValueByName = {
    OrderPlaced: "0",
    OrderAccepted: "1",
    OrderReadyforDelivery: "2",
    OrderOutofDelivery: "3",
    OrderDelivered: "4",
    OrderCancelled: "5"
}