import { Button, Card, CardActions, CardContent, CardMedia, Grid, IconButton, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export const ProductCard = ({ data, show, add, remove, cartsData }) => {
    const addedToCart = cartsData.find(obj => obj.product_id === data.id);
    return (
        <Card sx={{ width: "100%", height: "100%" }}>
            <CardMedia
                sx={{ height: 140, cursor: "pointer" }}
                image={data?.image}
                title={data?.name}
                onClick={() => show(data)}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" onClick={() => show(data)} sx={{ height: "2rem", overflow: "hidden", cursor: "pointer" }}>
                    {data?.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ height: "4rem", overflow: "hidden" }}>
                    {data?.description}
                </Typography>
                <Typography variant="subtitle1" color={"secondary"}>
                    <strong>Rs. {data?.price}</strong>
                </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
                {
                    addedToCart
                        ?
                        <Grid item xs={4} display={"flex"} flexDirection={"row"} alignItems={"center"}>
                            <IconButton onClick={() => remove(data.id)} sx={{ p: "2px", border: "1px solid" }} size="small" color="secondary"><RemoveIcon /></IconButton>
                            <Typography variant="BUTTON" sx={{ p: 1 }}>{addedToCart.qty}</Typography>
                            <IconButton onClick={() => add(data)} sx={{ p: "2px", border: "1px solid" }} size="small" color="secondary"><AddIcon /></IconButton>
                        </Grid>
                        :
                        <Button variant="outlined" color="secondary" onClick={() => add(data)}>Add to cart</Button>
                }

                <Button variant="text" color="secondary" onClick={() => show(data)}>More</Button>
            </CardActions>
        </Card>
    )
}