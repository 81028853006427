import { Grid, Typography } from "@mui/material"
import CategorySkeleton from "./Common/CategorySkeleton";
import { useGetOrderDataQuery, useUpdateOrderMutation } from "../store/order/orderApis";
import { OrderCard } from "./OrderCard";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../store/common/commonSlice";
import { useState } from "react";
import { OrderStatusValueByName } from "../helpers/constants";

export const MyOrdersComponent = ({ show, navigate }) => {
    const { isLoading, data } = useGetOrderDataQuery({}, { skip: !show });

    const dispatch = useDispatch();
    const [handleOrderStatusUpdate, { isLoading: orderUpdateLoading }] = useUpdateOrderMutation();
    const [reason, setReason] = useState("");

    const handleOrderUpdate = async (data) => {
        let payload = {
            order_number: data,
            status: OrderStatusValueByName.OrderCancelled,
            remarks: reason
        }

        handleOrderStatusUpdate(payload).unwrap().then(fulfilled => {
            dispatch(setSnackBar({
                open: true,
                message: fulfilled.message,
                severity: "success",
            }))
        }).catch(rejected => dispatch(setSnackBar({
            open: true,
            message: rejected.message,
            severity: "error",
        })));
    }

    return (
        <>
            {
                isLoading
                    ?
                    <CategorySkeleton />
                    :
                    <Grid container sx={{ display: "flex" }} direction={"column"} width={"100%"} height={"100%"} >
                        <Grid container flex={0} px={2} py={1} borderBottom={1} borderColor={"rgba(5, 5, 5, 0.06)"}>
                            <Grid item alignSelf={"center"}>
                                <Typography variant="h6">My Orders</Typography>
                            </Grid>
                        </Grid>
                        <Grid flex={1} px={2} py={5} overflow={"auto"}>
                            <Grid container spacing={2} >
                                {
                                    data?.result && data?.result?.length > 0 ?
                                        data?.result.map((val, index) => {
                                            return (
                                                <Grid item xs={12} key={index}>
                                                    <OrderCard navigate={navigate} loading={orderUpdateLoading} submitOrder={handleOrderUpdate} setReason={(val) => setReason(val)} data={val} />
                                                </Grid>
                                            )
                                        })
                                        :
                                        null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
            }
        </>
    )
}