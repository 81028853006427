import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import CartsPage from "../pages/CartsPage";
import ProfilePage from "../pages/ProfilePage";
import TrackOrder from "../pages/TrackOrder";
import { isAuth } from "../helpers/cookies";

const PrivateRoutes = (props) => {
    return (
        <Routes>
            <Route path="/*" element={<HomePage {...props} />} />
            <Route path="/carts" element={<CartsPage {...props} />} />
            {
                !isAuth()
                    ?
                    <Route path="/login" element={<LoginPage {...props} />} />
                    :
                    <>
                        <Route path="/track-order/:id" element={<TrackOrder {...props} />} />
                        <Route path="/profile" element={<ProfilePage {...props} />} />
                    </>
            }

        </Routes>
    );
};

export default PrivateRoutes;