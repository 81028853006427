import { withTheme } from "@emotion/react";
import { Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import Input from "../../components/Common/Input";
import ArrowForward from '@mui/icons-material/ArrowForward';
import { Formik } from "formik";
import * as yup from "yup";
import Button from "../../components/Common/Button";
import { axios } from "../../helpers/axios";
import { authenticate } from "../../helpers/cookies";
import { useHandleMultipleCartMutation } from "../../store/cart/cartApis";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../store/common/commonSlice";
import { Link } from "react-router-dom";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const registerValidation = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    mobile: yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Please enter mobile number")
});
const loginValidation = yup.object().shape({
    mobile: yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Please enter mobile number")
});
const otpValidation = yup.object().shape({
    otp: yup.string().max(4).min(4).required("Please enter received OTP"),
})

const LoginPageContainer = (props) => {
    const [otpSent, setOtpSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [registerShow, setRegisterShow] = useState(false);
    const dispatch = useDispatch();
    const [handleMultipleCart] = useHandleMultipleCartMutation();

    const [formValidation, setFormValidation] = useState(loginValidation);

    const initialValues = {
        mobile: "",
    };

    const onFormSubmit = async (values) => {
        setLoading(true);
        try {
            if (values.otp) {
                const otpVerifyRes = await axios.post("/auth/verify-otp", values);
                authenticate(otpVerifyRes, () => {
                    if (props.cartsData && props.cartsData.length > 0) {
                        handleMultipleCart({ products: props.cartsData }).unwrap().then(fulfilled => {
                            window.location.href = "/carts";
                        }).catch(rejected => dispatch(setSnackBar({
                            open: true,
                            message: rejected.message,
                            severity: "error",
                        })));
                    } else {
                        window.location.href = "/"
                    }
                });
            } else {
                const otpSentRes = await axios.post("/auth/send-otp", values);
                if (otpSentRes.success) {
                    setOtpSent(true);
                    setFormValidation(otpValidation);
                } else {
                    setOtpSent(false);
                    setLoading(false);
                }
            }
        } catch (e) {
            setLoading(false);
        }
        setLoading(false);
    };

    const hanldeRegister = (e, status) => {
        e.preventDefault();
        setRegisterShow(status);
        if (status) {
            setFormValidation(registerValidation)
        } else {
            setFormValidation(loginValidation)
        }
    }

    return (
        <Stack spacing={1} sx={{ height: "100%" }}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                alignContent={"center"}
                sx={{ height: "100%" }}
            >
                <Grid item md={6} xs={12} p={1}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={(values) => onFormSubmit(values)}
                        validationSchema={formValidation}
                        enableReinitialize
                    >
                        {({
                            handleChange,
                            handleSubmit,
                            errors,
                        }) => (
                            <>
                                <Typography variant="h5" color={"secondary"} fontWeight={800} pb={1}>Hello there!</Typography>
                                <Typography variant="h6" fontWeight={500}>Welcome back</Typography>
                                <Typography variant="body2" fontWeight={400} pb={2}>Enter mobile number to continue </Typography>
                                {
                                    registerShow
                                        ?
                                        <>
                                            <Input
                                                label="Name"
                                                name="name"
                                                id="name"
                                                fullWidth
                                                color="primary"
                                                sx={{ pb: 2 }}
                                                onChange={handleChange("name")}
                                                error={errors.name && Boolean(errors.name)}
                                                helperText={errors.name}
                                                disabled={otpSent}
                                            />
                                            <Input
                                                label="Email"
                                                name="email"
                                                id="email"
                                                fullWidth
                                                color="primary"
                                                sx={{ pb: 2 }}
                                                onChange={handleChange("email")}
                                                error={errors.email && Boolean(errors.email)}
                                                helperText={errors.email}
                                                disabled={otpSent}
                                            />
                                        </>
                                        :
                                        null
                                }
                                <Input
                                    label="Mobile No."
                                    name="mobile"
                                    id="mobile"
                                    fullWidth
                                    color="primary"
                                    sx={{ pb: 2 }}
                                    onChange={handleChange("mobile")}
                                    error={errors.mobile && Boolean(errors.mobile)}
                                    helperText={errors.mobile}
                                    disabled={otpSent}
                                />
                                {
                                    otpSent
                                        ?
                                        <Input
                                            label="OTP"
                                            name="otp"
                                            id="otp"
                                            fullWidth
                                            color="primary"
                                            sx={{ pb: 2 }}
                                            onChange={handleChange("otp")}
                                            error={errors.otp && Boolean(errors.otp)}
                                            helperText={errors.otp}
                                        />
                                        :
                                        null
                                }

                                <Button onClick={handleSubmit} loading={loading} variant="contained" color="secondary" sx={{ width: "100%", justifyContent: "space-between" }} endIcon={<ArrowForward />}>
                                    {otpSent ? "Verify OTP" : registerShow ? "Register" : "Login"}
                                </Button>
                                {
                                    !otpSent
                                        ?
                                        registerShow
                                            ?
                                            <Typography variant="body2" fontWeight={400} pb={2} pt={2}>
                                                Already registered? <Link to={"/"} onClick={(e) => hanldeRegister(e, false)} >Click here</Link> to login
                                            </Typography>
                                            :
                                            <Typography variant="body2" fontWeight={400} pb={2} pt={2}>
                                                Not registered? <Link to={"/"} onClick={(e) => hanldeRegister(e, true)} >Click here</Link> to register
                                            </Typography>
                                        :
                                        <Typography variant="body2" fontWeight={400} pb={2} pt={2}>
                                            Change Number? <Link to="/" onClick={(e) => [e.preventDefault(), setOtpSent(false), setFormValidation(loginValidation)]}>Click here</Link>
                                        </Typography>
                                }

                            </>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Stack>
    );
}
export default withTheme(LoginPageContainer);
