import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Box, Grid } from "@mui/material";

const SingleProductSkeleton = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={9}>
                <Grid container spacing={5} pt={2}>
                    <Grid item xs={12}>
                        <Skeleton sx={{ height: 35, width: 500 }} animation="wave" variant="rectangular" />
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton sx={{ height: 25, width: 150 }} animation="wave" variant="rectangular" />
                        <Skeleton sx={{ height: 30, width: 150, mt: 2 }} animation="wave" variant="rectangular" />
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton sx={{ height: 25, width: 150 }} animation="wave" variant="rectangular" />
                        <Skeleton sx={{ height: 30, width: 150, mt: 2 }} animation="wave" variant="rectangular" />
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton sx={{ height: 25, width: 150 }} animation="wave" variant="rectangular" />
                        <Skeleton sx={{ height: 30, width: 150, mt: 2 }} animation="wave" variant="rectangular" />
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton sx={{ height: 25, width: 150 }} animation="wave" variant="rectangular" />
                        <Skeleton sx={{ height: 30, width: 150, mt: 2 }} animation="wave" variant="rectangular" />
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton sx={{ height: 25, width: 150 }} animation="wave" variant="rectangular" />
                        <Skeleton sx={{ height: 30, width: 150, mt: 2 }} animation="wave" variant="rectangular" />
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton sx={{ height: 25, width: 150 }} animation="wave" variant="rectangular" />
                        <Skeleton sx={{ height: 30, width: 150, mt: 2 }} animation="wave" variant="rectangular" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <Box p={2} width={"100%"}>
                    <Skeleton sx={{ height: 150, width: 150 }} animation="wave" variant="rectangular" />
                </Box>
            </Grid>
        </Grid>
    )
}


export default SingleProductSkeleton;
