import { Grid, Paper } from "@mui/material";
import Input from "./Common/Input";

export const SearchFilter = () => {
    return (
        <Paper variant="outlined" sx={{ borderRadius: 2, width: "100%", p: 2 }} >
            <Grid container display={"flex"} justifyContent={"flex-end"}>
                <Grid item>
                    <Input
                        label="Search here..."
                        color="secondary"
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}