export const generateHoursMinutes = () => {
    const hours = [...Array(24).keys()];
    const minutes = [...Array(60).keys()];

    let convertedTime = [];
    for (const h of hours) {
        for (const m of minutes) {
            convertedTime.push(h + ":" + m);
        }
    }
    return convertedTime;
};

export const generateDays = (date = new Date()) => {
    let dateArray = [new Date()];
    Array(6).fill(date).map((el) => {
        dateArray.push(new Date(el.setDate(el.getDate() + 1)))
        return true;
    })
    return dateArray;
}