import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import commonApis from "../../store/common/commonApis";
import { setLocationData, setDeliveryDate } from "../../store/common/commonSlice";

const mapStateToProps = (state) => {
    return {
        locationData: state.common.locationData,
        deliveryDate: state.common.deliveryDate,
        cartsItemCount: state.cart.cartsItemCount
    };
};

const mapDispatch = {
    getLocationList: commonApis.endpoints.getLocationList.initiate,
    setLocationData,
    setDeliveryDate
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(mapDispatch, dispatch);

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;