import { useTheme, withTheme } from "@emotion/react";
import { Box, Grid, Paper, Skeleton, Stack, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetOrderDataQuery } from "../../store/order/orderApis";
import { OrderStatus, OrderStatusValueByName } from "../../helpers/constants";
import chefImage from "../../assets/chef.png";
import bikeImage from "../../assets/bike.png";
import deliveredImage from "../../assets/delivered.jpg";

const TrackPageContainer = (props) => {
    const { isLoading, data } = useGetOrderDataQuery({ order_number: props.params.id }, { skip: !props.params.id });
    const [finalResult, setFinalResult] = useState(false);

    useEffect(() => {
        if (data?.result.length > 0) {
            setFinalResult(data?.result[0]);
        }
    }, [data]);

    return (
        <Stack >
            <Grid container alignContent={"flex-start"} justifyContent={"center"}>
                <Grid item md={8} xs={12} >
                    <Paper variant="outlined" sx={{ borderRadius: 2, p: 2, width: "100%", textAlign: "center" }}>
                        {
                            isLoading
                                ?
                                <Skeleton animation="wave" height={90} />
                                :
                                <>
                                    <Typography variant="h4" fontWeight={"bold"}>{OrderStatus[finalResult.status]}</Typography>
                                    {
                                        finalResult.status === OrderStatusValueByName.OrderAccepted || finalResult.status === OrderStatusValueByName.OrderPlaced
                                            ?
                                            <>
                                                <img src={chefImage} alt="Chef" width={300} />
                                                <Typography variant="h6" fontWeight={"bold"}>Your Wholesome goodness is being freshly made!</Typography>
                                            </>
                                            :
                                            null
                                    }
                                    {
                                        finalResult.status === OrderStatusValueByName.OrderOutofDelivery
                                            ?
                                            <img src={bikeImage} alt="Chef" width={300} />
                                            :
                                            null
                                    }
                                    {
                                        finalResult.status === OrderStatusValueByName.OrderDelivered
                                            ?
                                            <>
                                                <img src={deliveredImage} alt="Chef" width={300} />
                                                <Typography variant="h6" fontWeight={"bold"}>Yay! Da wholesome goodness is delivered!</Typography>
                                            </>
                                            :
                                            null
                                    }

                                    <Typography variant="body2">Write to <a href='mailto:customercare@pos.com'>customercare@pos.com</a> for any feedback.</Typography>

                                    <Typography variant="body2">We have sent you an SMS confirmation of your order. Please call us in case of queries :</Typography>

                                    <a href={"tel: 123123123"}> Call Us</a>

                                    <Typography variant="body2">Return to home page</Typography>
                                    <a href="/" >Click Here</a>
                                </>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Stack >
    );
}
export default withTheme(TrackPageContainer);
