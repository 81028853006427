import { get } from "lodash-es";
import { setCookie } from "../../helpers/cookies";

export const cartDataParser = (response) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        let state_cart = []

        response?.carts_items?.map((obj) => {
            return state_cart.push({
                cart_id: response.id,
                cart_item_id: obj.id,
                product_id: obj.product_id,
                name: obj.product_name,
                qty: obj.qty,
                price: obj.price,
                image: obj.image,
            })
        })

        return { api_cart: response, state_cart, item_count: response.item_count }

    } catch (error) {
        throw new Error(error);
    }
}