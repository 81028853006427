import { Card, CardContent, CardHeader, MenuItem, MenuList, Paper } from "@mui/material"
import { useGetCategoryListQuery } from "../store/common/commonApis";
import CategorySkeleton from "./Common/CategorySkeleton";

export const CategoryListing = ({ categoryId, setCategoryId }) => {
    const { isLoading, data } = useGetCategoryListQuery();

    return (
        <Paper variant="outlined" sx={{ borderRadius: 2, width: "100%" }}>
            <Card sx={{ p: 2, borderRadius: 2 }}>
                <CardHeader title="Categories" titleTypographyProps={{ variant: 'h5', fontWeight: "600" }} />
                <CardContent sx={{ p: 0 }}>
                    <MenuList>
                        <MenuItem onClick={() => setCategoryId(false)} selected={!categoryId ? true : false}>All Products</MenuItem>
                        {
                            isLoading
                                ?
                                <CategorySkeleton />
                                :
                                data && data.result?.result && data.result.result.map((val, ind) => {
                                    return (
                                        <MenuItem onClick={() => setCategoryId(val.id)} selected={categoryId === val.id} key={ind}>{val.name}</MenuItem>
                                    )
                                })
                        }
                    </MenuList>

                </CardContent>
            </Card>
        </Paper>
    )
}