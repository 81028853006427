import { FormControlLabel, Grid, Paper, Radio, RadioGroup, Typography, useRadioGroup } from "@mui/material"
import styled from "@emotion/styled";
import { useState } from "react";

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
        '.MuiFormControlLabel-label': checked && {
            color: theme.palette.secondary.main,
        },
    }),
);

function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}

export const PaymentComponent = ({ show }) => {
    const [paymentMode, setPaymentMode] = useState("cod");

    return (
        <Grid item xs={6} sx={{ display: show ? "block" : "none" }}>
            <Paper sx={{ p: 3 }}>
                <Grid container >
                    <Grid item xs={12}>
                        <Typography variant="h6" fontWeight={700}>Payment Mode</Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <RadioGroup name="use-radio-group" defaultValue={paymentMode}>
                            <MyFormControlLabel value="cod" color="secondary" onChange={(e) => setPaymentMode(e.target.value)} label="Cash on Delivery" control={<Radio color="secondary" />} />
                        </RadioGroup>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}