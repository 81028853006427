import { withTheme } from "@emotion/react";
import { Box, Divider, Grid, Link, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetCartDataQuery, useHandleMultipleCartMutation } from "../../store/cart/cartApis";
import VerifiedIcon from '@mui/icons-material/Verified';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { CheckoutComponent } from "../../components/CheckoutComponent";
import { DeliveryInfo } from "../../components/DeliveryInfo";
import { TipInfo } from "../../components/TipInfo";
import { OrderSummary } from "../../components/OrderSummary";
import { PromoCode } from "../../components/PromoCode";
import { DeliveryDetailsComponent } from "../../components/DeliveryDetailsComponent";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../store/common/commonSlice";

import { PaymentComponent } from "../../components/PaymentComponent";
import { usePlaceOrderMutation } from "../../store/order/orderApis";
import Button from "../../components/Common/Button";
import { EmptyCartIcon } from "../../assets/Icons";
import { isAuth } from "../../helpers/cookies";

const CartsPageContainer = (props) => {
    const { isLoading, data } = useGetCartDataQuery();
    const [currentStage, setCurrentStage] = useState("checkout");
    const dispatch = useDispatch();
    const [handleMultipleCart] = useHandleMultipleCartMutation();
    const [handlePlaceOrder, { isLoading: orderPlaceLoading }] = usePlaceOrderMutation();
    const [selectedAddress, setSelectedAddress] = useState("new");
    const [instruction, setInstruction] = useState("");

    const handleCartSubmitApi = async (data) => {
        handleMultipleCart({ products: data }).unwrap().then(fulfilled => {
            dispatch(setSnackBar({
                open: true,
                message: fulfilled.message,
                severity: "success",
            }))
        }).catch(rejected => dispatch(setSnackBar({
            open: true,
            message: rejected.message,
            severity: "error",
        })));
    }

    useEffect(() => {
        if (!isLoading) {
            props.setCartsItemCount(data?.item_count || 0);
            props.setCartsData(data?.state_cart || []);
        }
    }, [isLoading, data])

    const handleCheckoutApi = async () => {
        if (!selectedAddress) {
            dispatch(setSnackBar({
                open: true,
                message: "Please select address from the list",
                severity: "error",
            }))
            return false;
        }
        const orderPayload = {
            cart_id: data?.api_cart.id,
            customer_address_id: selectedAddress.toString(),
            payment_method: "cod",
            instruction: instruction,
            delivery_date: props.deliveryDate
        }
        handlePlaceOrder(orderPayload).unwrap().then(fulfilled => {
            console.log(fulfilled, "fulfilled");
            dispatch(setSnackBar({
                open: true,
                message: fulfilled.message,
                severity: "success",
            }))
            props.setCartsItemCount(0);
            props.setCartsData([]);
            props.navigate("/track-order/" + fulfilled?.result.id)
        }).catch(rejected => {
            dispatch(setSnackBar({
                open: true,
                message: rejected.data.message,
                severity: "error",
            }))
        });
    }

    const handleContinue = async () => {
        if (currentStage === "checkout") {
            setCurrentStage("delivery");
        } else if (currentStage === "delivery") {
            if (selectedAddress !== "new") {
                setCurrentStage("payment");
            }
        } else if (currentStage === "payment") {
            handleCheckoutApi()
        }
    }

    const handleBack = async () => {
        if (currentStage === "delivery") {
            setCurrentStage("checkout");
        } else if (currentStage === "payment") {
            setCurrentStage("delivery");
        } else if (currentStage === "payment") {

        }
    }

    const handleAddToCart = (product) => {
        let existingData = JSON.parse(JSON.stringify(props.cartsData));
        let findIndex = existingData.findIndex(obj => obj.product_id === product.id);

        if (findIndex >= 0) {
            existingData[findIndex].qty++;
        } else {
            existingData.push({
                product_id: product.id,
                image: product.image,
                price: product.price,
                qty: 1
            })
        }
        props.setCartsItemCount(props.cartsItemCount + 1);
        props.setCartsData(existingData);
        handleCartSubmitApi(existingData);
    }

    const handleRemoveCart = (id, remove = false) => {
        if (!id) {      // clearing cart here
            props.setCartsData([]);
            props.setCartsItemCount(0);
            return false;
        }
        let existingData = JSON.parse(JSON.stringify(props.cartsData));
        let findIndex = existingData.findIndex(obj => obj.product_id === id);

        if (findIndex >= 0) {
            if (existingData.length === 1 && existingData[findIndex].qty === 1) {
                existingData = [];
                props.setCartsItemCount(0);
            } else if (existingData[findIndex].qty === 1 || remove) {
                props.setCartsItemCount(props.cartsItemCount - existingData[findIndex].qty);
                existingData.splice(findIndex, 1);
            } else {
                existingData[findIndex].qty--;
                props.setCartsItemCount(props.cartsItemCount - 1)
            }
        }
        props.setCartsData(existingData);
        handleCartSubmitApi(existingData);
    }

    return (
        <Stack sx={{ height: "100%" }}>
            {
                !props.cartsItemCount
                    ?
                    <Grid container pb={3} sx={{ overflow: "auto", height: "100%" }}>
                        <Grid item xs={12} alignSelf={"center"} textAlign={"center"}>
                            <EmptyCartIcon />
                            <Typography variant="h6">Your cart is empty</Typography>
                            <Typography variant="body2">Please add some items from the menu</Typography>
                            <Button sx={{ marginTop: 1 }} color="secondary" variant="outlined" fullWidth={false} onClick={() => props.navigate("/")}>Go to Home</Button>
                        </Grid>
                    </Grid>
                    :
                    <form method="post" action="/" onClick={(e) => e.preventDefault()}>
                        <Grid container sx={{ height: "100%" }} alignContent={"flex-start"}>
                            <Grid item md={12} xs={12} textAlign={"center"} pb={3}>
                                <Typography variant="h3" fontWeight={"800"}>Checkout</Typography>
                            </Grid>
                            <Grid item md={12} xs={12} textAlign={"center"} display={"flex"} alignItems={"center"} flexDirection={"row"} justifyContent={"center"} pb={3}>
                                <Box display={"flex"} alignItems={"center"} flexDirection={"row"} justifyContent={"center"}>
                                    <VerifiedIcon color={currentStage === "delivery" || currentStage === "payment" ? "success" : ""} />
                                    <Typography variant="h6" pl={2} pr={2}>Checkout</Typography>
                                    <ArrowRightAltIcon />
                                </Box>
                                <Box display={"flex"} alignItems={"center"} flexDirection={"row"} justifyContent={"center"} pl={2}>
                                    <VerifiedIcon color={currentStage === "payment" ? "success" : ""} />
                                    <Typography variant="h6" pl={2} pr={2}>Delivery Details</Typography>
                                    <ArrowRightAltIcon />
                                </Box>
                                <Box display={"flex"} alignItems={"center"} flexDirection={"row"} justifyContent={"center"} pl={2}>
                                    <VerifiedIcon />
                                    <Typography variant="h6" pl={2} pr={2}>Payment</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={12} xs={12} pb={3}>
                                <Grid container justifyContent={"center"} spacing={2}>

                                    <CheckoutComponent show={currentStage === 'checkout'} add={handleAddToCart} remove={handleRemoveCart} cartsItemCount={props.cartsItemCount} cartsData={props.cartsData} showLocation={props.showLocation} locationData={props.locationData} deliveryDate={props.deliveryDate} />
                                    <DeliveryDetailsComponent pincode={props?.locationData?.pincode || ""} instruction={instruction} setInstruction={(val) => setInstruction(val)} selectedAddress={selectedAddress} setSelectedAddress={(val) => setSelectedAddress(val)} show={currentStage === 'delivery'} />
                                    <PaymentComponent show={currentStage === "payment"} />

                                    <Grid item xs={3}>
                                        <DeliveryInfo showLocation={props.showLocation} locationData={props.locationData} deliveryDate={props.deliveryDate} />
                                        {
                                            currentStage === 'checkout' && props.cartsData.length > 0 ? <TipInfo cartsData={props.cartsData} /> : null
                                        }
                                        <OrderSummary cartsData={data?.api_cart} />
                                        {/* {
                                currentStage === 'checkout' ? <PromoCode /> : null
                            } */}

                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item md={12} xs={12} pb={3}>
                                <Divider />
                            </Grid>
                            <Grid item md={12} xs={12} textAlign={"center"} pb={3}>
                                <Link color={"secondary"} sx={{ pr: 3 }}>Continue Shopping</Link>
                                {
                                    currentStage !== 'checkout'
                                        ?
                                        <Button variant="outlined" fullWidth={false} color="secondary" onClick={() => handleBack()} size="large" sx={{ mr: 3 }}>Back</Button>
                                        :
                                        null
                                }
                                <Button loading={orderPlaceLoading} fullWidth={false} variant="contained" color="secondary" size="large" type="submit" onClick={() => handleContinue()}>Continue</Button>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                    </form>
            }
        </Stack >
    );
}
export default withTheme(CartsPageContainer);
