import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { useState } from "react";
import { format } from "date-fns";
import { OrderStatus, OrderStatusValueByName } from "../helpers/constants";
import StyledTextarea from "./Common/StyledTextarea";


export const OrderCard = ({ data, setReason, submitOrder, loading, navigate }) => {

    const [orderCancel, setOrderCancel] = useState(false);

    const handleRepeatOrder = (data) => {
        let payload = [];
        data.order_items.map((obj) => {
            payload.push({
                product_id: obj.product_id,
                image: obj.image,
                name: obj.product_name,
                price: obj.price,
                qty: 1
            })
            return true;
        })
        navigate("/", { state: { product: payload } })
    }

    return (
        <Card
            sx={{ width: "100%", height: "100%", border: "1px solid", borderColor: "rgba(5, 5, 5, 0.06)" }}
        >
            <CardContent>
                {
                    orderCancel
                        ?
                        <Box display={"block"} >
                            <StyledTextarea fullWidth minRows={3} onChange={(e) => setReason(e.target.value)} sx={{ pb: 2 }} label="Enter your reason to cancel the order" />
                            <Button loading={loading} variant="contained" color="secondary" onClick={() => [submitOrder(data.order_number), setOrderCancel(false)]}>Submit</Button>
                            <Button variant="text" color="secondary" onClick={() => setOrderCancel(false)}>Don't cancel</Button>
                        </Box>
                        :
                        <>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Typography variant="body2">Order Total:  <strong>{data.grand_total}/-</strong></Typography>
                                <Typography variant="body2">Ordered on : <strong>{data.createdAt ? format(new Date(data.createdAt), "dd/MM/yyyy") : "-"}</strong></Typography>
                            </Box>
                            <Typography variant="body2">Status:  <strong>{OrderStatus[data.status]}</strong></Typography>
                            <Typography variant="body2">Items:</Typography>
                            <ul style={{ margin: 0 }}>
                                {
                                    data.order_items.map((dd, key) => {
                                        return (
                                            <li key={key}><Typography variant="body2">{dd.product_name} ({dd.qty} * {dd.price})</Typography></li>
                                        )
                                    })
                                }

                            </ul>
                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant="body2">Order No.: <strong>{data.order_number}</strong></Typography>
                                {
                                    data.status === OrderStatusValueByName.OrderPlaced
                                        ?
                                        <Button variant="contained" color="secondary" onClick={() => setOrderCancel(true)}>Cancel Order</Button>
                                        :
                                        null
                                }

                                {
                                    data.status === OrderStatusValueByName.OrderDelivered
                                        ?
                                        <Button variant="contained" color="secondary" onClick={() => handleRepeatOrder(data)}>Repeat Order</Button>
                                        :
                                        null
                                }
                            </Box>
                        </>
                }
            </CardContent>
        </Card>
    )
}