import { Grid, Stack, Typography } from "@mui/material"
import { SearchFilter } from "./SearchFilter"
import { ProductCard } from "./ProductCard"
import ProductSkeleton from "./Common/ProductSkeleton";
import { useState } from "react";
import ProductModel from "./ProductModel";

export const ProductListing = ({ add, remove, cartsData, isLoading, data }) => {

    const [showProductModel, setShowProductModel] = useState(false);

    return (
        <Stack spacing={2} sx={{ height: "100%", pl: { xs: 0, md: 3 }, pr: { xs: 0, md: 3 } }}>
            <SearchFilter />

            <Grid container sx={{ height: { xs: "100%", md: "80vh" }, overflow: { xs: "hidden", md: "auto" } }} alignContent={"flex-start"}>
                <Grid item xs={12} sx={{ p: { xs: 0, md: 1 }, pb: { xs: 2, md: 0 } }}>
                    <Typography variant="h6">All Products</Typography>
                </Grid>
                {
                    isLoading
                        ?
                        <ProductSkeleton />
                        :
                        data?.result && data.result?.result.map(function (val, ind) {
                            return (
                                <Grid key={ind} item xs={12} md={4} sx={{ p: { xs: 0, md: 1 }, pb: { xs: 2, md: 0 }, minHeight: 280 }}>
                                    <ProductCard add={add} remove={remove} cartsData={cartsData} data={val} show={(d) => setShowProductModel(d)} />
                                </Grid>
                            )
                        })
                }
            </Grid>

            <ProductModel productData={showProductModel} show={showProductModel ? true : false} hide={() => setShowProductModel(false)} />

        </Stack >
    )
}