import { withTheme } from "@emotion/react";
import { Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CategoryListing } from "../../components/CategoryListing";
import { ProductListing } from "../../components/ProductListing";
import { CartData } from "../../components/CartData";
import { useGetProductListQuery } from "../../store/common/commonApis";
import { useClearCartMutation, useGetCartDataQuery } from "../../store/cart/cartApis";
import { useLocation, createhis } from 'react-router-dom';

const HomePageContainer = (props) => {
    const [categoryId, setCategoryId] = useState(false);
    const { isLoading, data } = useGetProductListQuery(categoryId ? { category_id: categoryId, limit: 150 } : { limit: 150 });
    const { isLoading: cartLoading, data: cartData } = useGetCartDataQuery();
    const [handleClearCart] = useClearCartMutation();
    const location = useLocation();

    useEffect(() => {
        if (!cartLoading) {
            props.setCartsItemCount(cartData?.item_count || 0);
            props.setCartsData(cartData?.state_cart || []);

            if (location.state && location.state.product && location.state.product.length > 0) {
                let product = location.state.product;
                props.setCartsItemCount(product.length);
                props.setCartsData(product);
                window.history.replaceState({}, document.title);
            }
        }
    }, [cartLoading, cartData, location]);

    const handleAddToCart = (product) => {
        let existingData = JSON.parse(JSON.stringify(props.cartsData));
        let findIndex = existingData.findIndex(obj => obj.product_id === product.id);

        if (findIndex >= 0) {
            existingData[findIndex].qty++;
        } else {
            existingData.push({
                product_id: product.id,
                image: product.image,
                price: product.price,
                name: product.name,
                qty: 1
            })
        }
        props.setCartsItemCount(props.cartsItemCount + 1);
        props.setCartsData(existingData);
    }

    const handleRemoveCart = async (id, remove = false) => {
        if (!id) {      // clearing cart here
            if (cartData?.api_cart && cartData?.api_cart.id)
                await handleClearCart({ cart_id: cartData.api_cart.id });
            props.setCartsData([]);
            props.setCartsItemCount(0);
            return false;
        }
        let existingData = JSON.parse(JSON.stringify(props.cartsData));
        let findIndex = existingData.findIndex(obj => obj.product_id === id);

        if (findIndex >= 0) {
            if (existingData.length === 1 && existingData[findIndex].qty === 1) {
                existingData = [];
                props.setCartsItemCount(0);
            } else if (existingData[findIndex].qty === 1 || remove) {
                props.setCartsItemCount(props.cartsItemCount - existingData[findIndex].qty);
                existingData.splice(findIndex, 1);
            } else {
                existingData[findIndex].qty--;
                props.setCartsItemCount(props.cartsItemCount - 1)
            }
        }
        props.setCartsData(existingData);
    }

    return (
        <Stack spacing={1} sx={{ height: "100%" }}>
            <Grid container sx={{ height: "100%" }}>
                <Grid item md={2} xs={12} sx={{ flexGrow: 1, display: { xs: 'flex', md: "flex" } }}>
                    <CategoryListing categoryId={categoryId} setCategoryId={(id) => setCategoryId(id)} />
                </Grid>
                <Grid item xs={12} md={7}>
                    <ProductListing isLoading={isLoading} data={data} add={handleAddToCart} remove={handleRemoveCart} cartsData={props.cartsData} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <CartData navigate={props.navigate} add={handleAddToCart} remove={handleRemoveCart} cartsData={props.cartsData} showLocation={props.showLocation} locationData={props.locationData} deliveryDate={props.deliveryDate} />
                </Grid>
            </Grid>
        </Stack>
    );
}
export default withTheme(HomePageContainer);
