import { Divider, Grid, Paper, Typography } from "@mui/material"

export const OrderSummary = ({ cartsData }) => {
    return (
        <Paper variant="outlined" sx={{ borderRadius: 2, width: "100%", p: 2, mt: 2, position: "relative" }}>
            <Grid container pb={3}>
                <Grid item xs={12}>
                    <Typography variant="h6" color={"primary"}>Order Summary</Typography>
                </Grid>
            </Grid>
            <Grid container display={"flex"} justifyContent={"space-between"}>
                <Typography variant="h6">Item Total</Typography>
                <Typography variant="h6" fontWeight={"700"}>{cartsData?.subtotal?.toFixed(2)}</Typography>
            </Grid>
            <Grid container display={"flex"} justifyContent={"space-between"}>
                <Typography variant="h6">Discount (-)</Typography>
                <Typography variant="h6" fontWeight={"700"}>{cartsData?.offer_discount?.toFixed(2) || 0.00}</Typography>
            </Grid>
            <Grid container display={"flex"} justifyContent={"space-between"}>
                <Typography variant="h6" >Loyalty Points (-):</Typography>
                <Typography variant="h6" fontWeight={"700"}>{cartsData?.applied_coins?.toFixed(2) || 0.00}</Typography>
            </Grid>
            <Grid container display={"flex"} justifyContent={"space-between"} pb={3}>
                <Typography variant="h6">Convenience Fee</Typography>
                <Typography variant="h6" fontWeight={"700"}>{cartsData?.convenience_fee?.toFixed(2) || 0.00}</Typography>
            </Grid>
            <Divider />
            <Grid container display={"flex"} justifyContent={"space-between"} pt={3} pb={3}>
                <Typography variant="h6">Total:</Typography>
                <Typography variant="h6" fontWeight={"700"}>{(parseInt(cartsData?.subtotal || 0) + parseInt(cartsData?.convenience_fee || 0)) - (parseInt(cartsData?.offer_discount || 0) + parseInt(cartsData?.applied_coins || 0))}</Typography>
            </Grid>
            <Grid container display={"flex"} justifyContent={"space-between"}>
                <Typography variant="h6">CGST (2.5%)</Typography>
                <Typography variant="h6" fontWeight={"700"}>{cartsData?.cgst?.toFixed(2) || 0.00}</Typography>
            </Grid>
            <Grid container display={"flex"} justifyContent={"space-between"}>
                <Typography variant="h6">SGST (2.5%)</Typography>
                <Typography variant="h6" fontWeight={"700"}>{cartsData?.sgst?.toFixed(2) || 0.00}</Typography>
            </Grid>
            <Grid container display={"flex"} justifyContent={"space-between"}>
                <Typography variant="h6">TIP</Typography>
                <Typography variant="h6" fontWeight={"700"}>{cartsData?.tip?.toFixed(2) || 0.00}</Typography>
            </Grid>
            <Grid container display={"flex"} justifyContent={"space-between"} pt={1}>
                <Typography variant="h5" color={"secondary"}>Grand Total:</Typography>
                <Typography variant="h5" color={"secondary"} fontWeight={"700"}>{cartsData?.grand_total?.toFixed(2)}</Typography>
            </Grid>
        </Paper >
    )
}