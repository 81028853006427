import { Card, CardActions, CardContent, CardHeader, Grid, Typography } from "@mui/material"
import Input from "./Common/Input"
import { Formik } from "formik";
import * as yup from "yup";
import { useGetUserProfileQuery, useUpdateUserProfileMutation } from "../store/user/userApis";
import CategorySkeleton from "./Common/CategorySkeleton";
import Button from "./Common/Button";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../store/common/commonSlice";
import userImg from "../assets/user.png";

const formValidation = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email().required("Email is required"),
});

export const ProfileComponent = () => {
    const { isLoading, data } = useGetUserProfileQuery();
    const dispatch = useDispatch();
    const [handleProfileUpdate, { isLoading: profileUpdateLoading }] = useUpdateUserProfileMutation();

    const handleProfileSubmit = async (data) => {
        let payload = {
            id: data.id,
            name: data.name,
            email: data.email
        }
        handleProfileUpdate(payload).unwrap().then(fulfilled => {
            dispatch(setSnackBar({
                open: true,
                message: fulfilled.message,
                severity: "success",
            }))
        }).catch(rejected => dispatch(setSnackBar({
            open: true,
            message: rejected.message,
            severity: "error",
        })));
    }

    return (
        <>
            {
                isLoading
                    ?
                    <CategorySkeleton />
                    :
                    <Formik
                        initialValues={data.result}
                        onSubmit={(values) => handleProfileSubmit(values)}
                        validationSchema={formValidation}
                    >
                        {({
                            handleChange,
                            handleSubmit,
                            errors,
                            values
                        }) => (
                            <>
                                <Grid container sx={{ display: "flex" }} direction={"column"} width={"100%"} height={"100%"} >
                                    <Grid container flex={0} px={2} py={1} borderBottom={1} borderColor={"rgba(5, 5, 5, 0.06)"}>
                                        <Grid item alignSelf={"center"}>
                                            <Typography variant="h6">Update Profile</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} py={2} >
                                        <Grid item xs={12} md={12} textAlign={"center"}>
                                            <img src={userImg} alt="user" width={50} />
                                            <Typography variant="h6">{values?.name || "Your name here"}</Typography>
                                            <Typography variant="body2" fontWeight={500}>+91 {values?.mobile}</Typography>
                                            <Typography variant="body2" fontWeight={500}>Loyalty Points {values?.coins || 0}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid flex={1} px={2} py={2} overflow={"auto"}>
                                        <Grid container spacing={2} >
                                            <Grid item xs={12} md={6}>
                                                <Input
                                                    label="Name"
                                                    name="name"
                                                    id="name"
                                                    fullWidth
                                                    color="primary"
                                                    sx={{ pb: 2 }}
                                                    onChange={handleChange("name")}
                                                    error={errors.name && Boolean(errors.name)}
                                                    helperText={errors.name}
                                                    value={values?.name}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Input
                                                    label="Email"
                                                    name="email"
                                                    id="email"
                                                    fullWidth
                                                    color="primary"
                                                    sx={{ pb: 2 }}
                                                    onChange={handleChange("email")}
                                                    error={errors.email && Boolean(errors.email)}
                                                    helperText={errors.email}
                                                    value={values?.email}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid flexShrink={0} borderTop={1} borderColor={"rgba(152, 188, 252, 0.16)"} sx={{ padding: "8px 16px" }}>
                                        <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Grid sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                <Button size="large" type="submit" loading={profileUpdateLoading} color="secondary" onClick={handleSubmit}>Submit</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Formik>
            }
        </>
    )
}