import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";
import { orderDataParser } from './orderParser';

const orderApis = createApi({
    reducerPath: 'orderApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("token");
            if (token) {
                headers.set('Authorization', `BEARER ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 30,
    tagTypes: ["ORDER"],
    endpoints: build => ({
        getOrderData: build.query({
            query: (params) => ({
                url: `${baseUrl}/order/my-orders`,
                method: "GET",
                params: params
            }),
            transformResponse: (response) => orderDataParser(response),
            providesTags: ['ORDER'],
        }),
        placeOrder: build.mutation({
            query: (payload) => ({
                url: `${baseUrl}/order`,
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['ORDER'],
        }),
        updateOrder: build.mutation({
            query: (payload) => ({
                url: `${baseUrl}/order/status`,
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['ORDER'],
        }),
    }),
});

export const { useGetOrderDataQuery, usePlaceOrderMutation, useUpdateOrderMutation } = orderApis;

export default orderApis;