import React, { useEffect } from "react";

import PrivateRoutes from "../../routes/PrivateRoutes";
import { useTheme, withTheme } from "@emotion/react";
import { AppBar, Badge, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography, styled } from "@mui/material";
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocationModel from "../../components/LocationModel";
import { isAuth, signout } from "../../helpers/cookies";

const Main = styled('main')(({ theme }) => ({
    backgroundColor: theme?.palette?.background?.secondary,
    width: "calc(100%)",
    minHeight: "calc(100vh - 60px)",
    flexGrow: 1,
    padding: 20,
    marginTop: 60,
    borderRadius: "8px 8px 0px 0px",
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
}));

const LayoutContainer = (props) => {
    const theme = useTheme();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [showLocationModel, setShowLocationModel] = React.useState(props.locationData && props.deliveryDate ? false : true);

    useEffect(() => {
        if(props?.params && props.params["*"].includes("track-order")) {
            setShowLocationModel(false);
        }
    }, [props])

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleLocationModel = () => {
        if (props.locationData && props.deliveryDate) {
            setShowLocationModel(!showLocationModel);
        } else {
            setShowLocationModel(true);
        }
    }

    return (
        <Box sx={{ display: "flex" }}>
            <AppBar
                position='fixed'
            >
                <Container maxWidth="xxl">
                    <Toolbar disableGutters>
                        <Button size="large" color="inherit" onClick={() => props.navigate("/")}>
                            <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                            <Typography
                                variant="h6"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                }}
                            >
                                LOGO
                            </Typography>
                        </Button>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                <MenuItem onClick={() => [handleCloseNavMenu(), handleLocationModel()]}>
                                    <Typography textAlign="center">Location</Typography>
                                </MenuItem>
                                {
                                    isAuth()
                                        ?
                                        <>
                                            <MenuItem onClick={() => props.navigate("/profile")}>
                                                <Typography textAlign="center">Profile</Typography>
                                            </MenuItem>
                                            <MenuItem onClick={() => signout(() => window.location.reload())}>
                                                <Typography textAlign="center">Logout</Typography>
                                            </MenuItem>
                                        </>
                                        :
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <Typography textAlign="center">Login / Sign Up</Typography>
                                        </MenuItem>
                                }

                            </Menu>
                        </Box>
                        <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                        <Typography
                            variant="h5"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                            }}
                        >
                            LOGOs
                        </Typography>
                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <Button size="large" color="inherit">
                                <Badge badgeContent={17} color="secondary">
                                    <ShoppingCartIcon />
                                </Badge>
                            </Button>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <Button size="large" color="inherit" onClick={() => handleLocationModel()}>
                                <MyLocationIcon color="secondary" />
                                <Box display={'flex'} flexDirection={"column"}>
                                    <Typography variant="subtitle2" sx={{ ml: 1 }}>Location</Typography>
                                    <Typography variant="caption">{props?.locationData?.pincode}</Typography>
                                </Box>
                            </Button>
                            <Button size="large" color="inherit">
                                <ShoppingCartIcon color="secondary" />
                                <Typography variant="subtitle2" sx={{ ml: 1 }}>
                                    <Badge badgeContent={props.cartsItemCount} color="secondary" onClick={() => window.location.href = "/carts"}>
                                        Cart
                                    </Badge>
                                </Typography>
                            </Button>
                            {
                                isAuth()
                                    ?
                                    <Button size="large" color="inherit" onClick={() => props.navigate("/profile")}>
                                        <AccountCircleIcon color="secondary" />
                                        <Typography variant="subtitle2" sx={{ ml: 1 }}>
                                            Profile
                                        </Typography>
                                    </Button>
                                    :
                                    <Button size="large" color="inherit" onClick={() => props.navigate("/login")}>
                                        <Typography variant="subtitle2" sx={{ ml: 1 }}>
                                            Login / Sign Up
                                        </Typography>
                                    </Button>
                            }
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            <Main theme={theme}>
                <PrivateRoutes showLocation={handleLocationModel} />
            </Main>

            <LocationModel locationData={props.locationData} setLocationData={props.setLocationData} deliveryDate={props.deliveryDate} setDeliveryDate={props.setDeliveryDate} getLocationList={props.getLocationList} show={showLocationModel} hide={handleLocationModel} />
        </Box>
    );
}
export default withTheme(LayoutContainer);
