import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";

const userApis = createApi({
    reducerPath: 'userApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("token");
            if (token) {
                headers.set('Authorization', `BEARER ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 30,
    tagTypes: ["ADDRESS"],
    endpoints: build => ({
        getCustomerAddress: build.query({
            query: (params) => ({
                url: `${baseUrl}/auth/address`,
                method: "GET",
                params: params
            }),
            transformResponse: (response) => response,
            providesTags: ['ADDRESS'],
        }),
        addCustomerAddress: build.mutation({
            query: (body) => ({
                url: `${baseUrl}/auth/address`,
                method: "POST",
                body: body
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['ADDRESS'],
        }),
        updateCustomerAddress: build.mutation({
            query: (body) => ({
                url: `${baseUrl}/auth/address`,
                method: "PUT",
                body: body
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['ADDRESS'],
        }),
        deleteCustomerAddress: build.mutation({
            query: (params) => ({
                url: `${baseUrl}/auth/address`,
                method: "DELETE",
                params: params
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['ADDRESS'],
        }),
        getUserProfile: build.query({
            query: (params) => ({
                url: `${baseUrl}/auth/my-profile`,
                method: "GET",
                params: params
            }),
            transformResponse: (response) => response,
            providesTags: ['PROFILE'],
        }),
        updateUserProfile: build.mutation({
            query: (body) => ({
                url: `${baseUrl}/auth/my-profile`,
                method: "PUT",
                body: body
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['PROFILE'],
        }),
    }),
});

export const { useGetCustomerAddressQuery, useAddCustomerAddressMutation, useUpdateCustomerAddressMutation, useDeleteCustomerAddressMutation, useGetUserProfileQuery, useUpdateUserProfileMutation } = userApis;

export default userApis;