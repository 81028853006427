import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";
import { productParser } from './commonParser';

const commonApis = createApi({
    reducerPath: 'commonApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("token");
            if (token) {
                headers.set('Authorization', `BEARER ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 30,
    tagTypes: ["location", "category", "products", "singleProduct"],
    endpoints: build => ({
        getLocationList: build.query({
            query: (params) => ({
                url: `${baseUrl}/location`,
                method: "GET",
                params: params
            }),
            transformResponse: (response) => response,
            providesTags: ['location'],
        }),
        getCategoryList: build.query({
            query: (params) => ({
                url: `${baseUrl}/category`,
                method: "GET",
                params: params
            }),
            transformResponse: (response) => response,
            providesTags: ['category'],
        }),
        getProductList: build.query({
            query: (params) => ({
                url: `${baseUrl}/product/list`,
                method: "GET",
                params: params
            }),
            transformResponse: (response) => response,
            providesTags: ['products'],
        }),
        getSingleProduct: build.query({
            query: (params) => ({
                url: `${baseUrl}/product`,
                method: "GET",
                params: params
            }),
            transformResponse: (response) => productParser(response),
            providesTags: ['singleProduct'],
        }),
    }),
});

export const { useGetCategoryListQuery, useGetProductListQuery, useGetSingleProductQuery } = commonApis;

export default commonApis;