import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Card, CardContent, Grid } from "@mui/material";

const SkeletonComponent = () => {
    return (
        <Grid item xs={12} md={4} sx={{ p: { xs: 0, md: 1 }, pb: { xs: 2, md: 0 } }}>
            <Card sx={{ width: "100%" }}>
                <Skeleton sx={{ height: 140 }} animation="wave" variant="rectangular" />
                <CardContent>
                    <Skeleton sx={{ height: 30, width: 100 }} animation="wave" variant="rectangular" />
                    <Skeleton sx={{ height: 65, mt: 2 }} animation="wave" variant="rectangular" />
                    <Skeleton sx={{ height: 25, width: 100, mt: 1 }} animation="wave" variant="rectangular" />
                    <Skeleton sx={{ height: 40, mt: 1 }} animation="wave" variant="rectangular" />
                </CardContent>
            </Card>
        </Grid>
    )
}

const MultiplyDocumentSkeleton = () => {
    let multipleComponent = [];
    for (let i = 0; i < 3; i++) {
        multipleComponent.push(
            <SkeletonComponent key={"document_skeleton_key_" + i} />
        );
    }
    return multipleComponent;
};

const ProductSkeleton = (props) => {

    return (<MultiplyDocumentSkeleton />);
};

export default ProductSkeleton;
