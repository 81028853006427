import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { AppBar, Box, Grid, Tab, Tabs } from '@mui/material';
import { useGetSingleProductQuery } from '../store/common/commonApis';
import { useTheme } from '@emotion/react';
import SingleProductSkeleton from './Common/SingleProductSkeleton';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function ProductModel({ show, hide, productData }) {
    const { isLoading, data } = useGetSingleProductQuery({ id: productData?.id }, { skip: Boolean(!show) });

    const theme = useTheme();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <BootstrapDialog
                onClose={hide}
                aria-labelledby="customized-dialog-title"
                open={show}
                maxWidth={'md'}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={hide}>
                    {productData?.name}
                </BootstrapDialogTitle>
                {
                    isLoading
                        ?
                        <DialogContent dividers sx={{ backgroundColor: "#ECEBEF" }}>
                            <SingleProductSkeleton />
                        </DialogContent>
                        :
                        <DialogContent dividers sx={{ backgroundColor: "#ECEBEF" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <Grid container spacing={5} pt={2}>
                                        <Grid item xs={12}>
                                            <Typography variant='h4' fontWeight={500}>{data?.name}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='body2' fontWeight={600} color={"#757f91"}>Cuisine</Typography>
                                            <Typography variant='h6' fontWeight={600}>{data?.cuisine}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='body2' fontWeight={600} color={"#757f91"}>Category</Typography>
                                            <Typography variant='h6' fontWeight={600}>{data?.category_name}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='body2' fontWeight={600} color={"#757f91"}>Ingredients</Typography>
                                            <Typography variant='h6' fontWeight={600}>{data?.ingredients.length}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='body2' fontWeight={600} color={"#757f91"}>Spice Level</Typography>
                                            <Typography variant='h6' fontWeight={600}>{data?.spice_evel || "-"}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='body2' fontWeight={600} color={"#757f91"}>Nutrition Values</Typography>
                                            <Typography variant='h6' fontWeight={600}>{data?.nutrition_values.length}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='body2' fontWeight={600} color={"#757f91"}>Best Before</Typography>
                                            <Typography variant='h6' fontWeight={600}>{data?.best_before}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box p={2} width={"100%"}>
                                        <img width={"100%"} src={data?.image} alt='Product' />
                                    </Box>
                                    <Box p={2} width={"100%"}>
                                        <img width={"100%"} src={data?.qr_code} alt='QR Code' />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} pt={5}>
                                <Box sx={{ width: '100%', typography: 'body1' }}>
                                    <AppBar position="static">
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="fullWidth"
                                            aria-label="full width tabs example"
                                        >
                                            <Tab color='secondary' label="Ingredients" value={0} />
                                            <Tab color='secondary' label="Nutrition Values" value={1} />
                                            <Tab color='secondary' label="Allergies" value={2} />
                                        </Tabs>
                                    </AppBar>
                                    <TabPanel value={value} index={0} dir={theme.direction}>
                                        {
                                            data?.ingredients?.map((dd, ind) => {
                                                return (
                                                    <Typography key={ind} pt={2} variant='body2'>{dd.name}</Typography>
                                                )
                                            })
                                        }
                                    </TabPanel>
                                    <TabPanel value={value} index={1} dir={theme.direction}>
                                        <Typography variant='body2' pt={2}>Per Serving {data?.serving}</Typography>
                                        {
                                            data?.nutrition_values?.map((dd, ind) => {
                                                return (
                                                    <Grid container pt={2}>
                                                        <Grid item xs={3}>
                                                            <Typography key={ind} variant='body2'>{dd.label}</Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography key={ind} variant='body2'>{dd.value}</Typography>
                                                        </Grid>
                                                    </Grid >
                                                )
                                            })
                                        }
                                    </TabPanel>
                                    <TabPanel value={value} index={2} dir={theme.direction}>
                                        {data?.allergies}
                                    </TabPanel>
                                </Box>
                            </Grid>
                        </DialogContent>
                }
            </BootstrapDialog>
        </div>
    );
}